import * as React from 'react';
import { IDataProvider } from '../../../../dataprovider/IDataProvider';
import { Utility } from '../../../Utility/Utility';
import { EventCalendar } from './Calendar';

export interface IDefaultViewProps {
    dataProvider: IDataProvider;
    masterData: any;
    loginUserDetail: any;
    isUserAdmin: boolean;
}

export interface IDefaultViewState {
}

export class UserEvents extends React.Component<IDefaultViewProps, IDefaultViewState> {
    constructor(MyProps: IDefaultViewProps) {
        Utility.removeEmptyDivIfPresent();
        super(MyProps);
        this.state = {
        };
    }

    public render() {

        return (
            <div className="panel cf_userDashBoardPanel">
                <EventCalendar
                    loginUserDetail={this.props.loginUserDetail}
                    dataProvider={this.props.dataProvider}
                    masterData={this.props.masterData}
                    isUserAdmin={this.props.isUserAdmin}
                />
            </div>
        );
    }

    public componentDidMount() {

    }

}