import { Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, Icon, Stack, TextField } from '@fluentui/react';
import { Label, StackItem } from 'office-ui-fabric-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CommentMode } from '../../../../Utility/FreelancerEnums';
import { Utility } from '../../../../Utility/Utility';
import '../Calendar.scss';
export interface IRenderApplicantProps {
    eventRequiredJobs: any,
    eventSubmissions: any,
    returnSelection: any,
}

var eventJobSubmissions: any = [];
export const RenderApplicant: React.FunctionComponent<IRenderApplicantProps> = ({ ...props }) => {
    let [eventJobSubmissionsState, SeteventJobSubmissions] = useState(eventJobSubmissions);
    let [showSelection, setSelection] = useState(props.returnSelection ? true : false);
    let [selectedUsersState, setSelectedUsers] = useState([]);

    // CheckForApplicant();
    function CheckForApplicant() {
        eventJobSubmissions = []
        props.eventRequiredJobs.forEach((requiredJob: any) => {
            var job: any = { title: requiredJob.title, Applicant: [] };
            var submissionsForJob = props.eventSubmissions.filter((eventSubmission: any) => {
                return eventSubmission.ID == requiredJob.id;
            });
            submissionsForJob.forEach((submissionForJob: any, index: number) => {
                /// check to add applicant only upto which require. Neglect if any more add
                job.Applicant.push({
                    Name: submissionForJob.FirstName + " " + submissionForJob.LastName,
                    Status: submissionForJob.Type,
                    Order: submissionForJob.Order,
                    Email: submissionForJob.Email,
                });
            });
            eventJobSubmissions.push(job);
        });
        SeteventJobSubmissions(eventJobSubmissions);
    }
    const onChange = (email: string, ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {

        var selectedUsers = selectedUsersState;
        if (selectedUsers.length > 0) {
            var filterEntry = selectedUsers.filter((submission: any) => {
                return submission.Email == email;
            });
            if (filterEntry.length > 0) {
                filterEntry[0].Checked = checked;
            }
            else {
                selectedUsers.push(
                    {
                        Email: email,
                        Checked: checked
                    }
                );
            }
        }
        else {
            selectedUsers.push(
                {
                    Email: email,
                    Checked: checked
                }
            );
        }
        setSelectedUsers(selectedUsers);
        props.returnSelection(selectedUsers);

        // // setIsChecked(!!checked);
        // eventJobSubmissions = eventJobSubmissionsState;
        // var filterEntry = eventJobSubmissions.filter((submission: any) => {
        //     return submission.Email == email;
        // });
        // if (filterEntry.length > 0) {
        //     filterEntry[0].Selected = checked;
        // }
        // SeteventJobSubmissions(eventJobSubmissions);
        // props.returnSelection(eventJobSubmissions);
    }
    // const onChange = React.useCallback(
    //     (email: string, ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    //         // setIsChecked(!!checked);
    //         eventJobSubmissions = eventJobSubmissionsState;
    //         var filterEntry = eventJobSubmissions.filter((submission: any) => {
    //             return submission.Email == email;
    //         });
    //         if (filterEntry.length > 0) {
    //             filterEntry[0].Selected = checked;
    //         }
    //         SeteventJobSubmissions(eventJobSubmissions);
    //         props.returnSelection(eventJobSubmissions);
    //     },
    //     [],
    // );
    useEffect(() => {
        // alert("render");
        CheckForApplicant();

    }, [JSON.stringify(props.eventRequiredJobs)]);
    return (
        <StackItem className='eventApplicantSec'>
            <Stack horizontal className='eventApplicant'>
                {
                    eventJobSubmissionsState.map((job: any) => {
                        return <Stack.Item className='eventJob' key={"job" + job.title}>
                            {
                                (
                                    (showSelection == true && job.Applicant.length > 0)
                                    ||
                                    (
                                        showSelection == false
                                    )
                                )
                                &&
                                <Stack className='eventJobDiv'>
                                    <Stack.Item className='jobTitle'>
                                        {job.title}
                                    </Stack.Item>
                                    {job.Applicant.length > 0 &&
                                        <Stack.Item className='jobApplicant'>
                                            {job.Applicant.map((applicant: any) => {
                                                return <div key={"jobApplicant" + applicant.Name} className='applicantName'>
                                                    {showSelection &&
                                                        <Checkbox className='mailCheck' onChange={(ev, checked) => onChange(applicant.Email, ev, checked)} />
                                                    }
                                                    <span title={applicant.Email} className='name'>{applicant.Name}</span>
                                                    <span className='type'>{applicant.Status + " " + applicant.Order}</span></div>
                                            })}
                                        </Stack.Item>
                                    }
                                </Stack>
                            }
                        </Stack.Item>

                    })
                }

            </Stack>
        </StackItem>
    );
};
