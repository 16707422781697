import { css, Checkbox, DefaultButton, IconButton, Label, mergeStyles, MessageBar, MessageBarType, Spinner, Stack, TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { IDataProvider } from '../../dataprovider/IDataProvider';
import { AccountType, IAuthenticatedUser, IUserAccount } from './LoginComponent';
import { GeneralHelper } from './LoginHelper';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import Button from "react-bootstrap/Button";
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Common } from './Common';
import { Badge, BadgeProps } from 'react-bootstrap';

require('./LoginComponent.css');


export interface ICustomAuthComponentProps {
    dataProvider: IDataProvider;
    setAuthStatus: (loggin: boolean, user: IAuthenticatedUser) => Promise<void>;
    onPanelStatusChange?: (isOpen: boolean) => void;
    currentUser: IAuthenticatedUser;
}

export interface ICustomAuthComponentState {
    state: "login" | "registration";
    currentUser: IUserAccount;

    firstNameErrorMessage: string;
    lastNameErrorMessage: string;
    emailErrorMessage: string;
    passwordErrorMessage: string;
    confirmPasswordErrorMessage: string;
    legalNoticeErrorMessage: string;
    privacyNoticeErrorMessage: string;

    pwdBadgeVariant: string;
    pwdBadgeLabel: string;

    informationType?: MessageBarType;
    informationMessage?: string;
    registered: boolean;
    loggedIn: boolean;
    confirmationPassword?: string;

    legalNoticeChecked: boolean;
    privacyNoticeChecked: boolean;

    isProcessing: boolean;
    showCustomLoginPanel: boolean;
    reCaptchaError: boolean;
    reCaptchaToken: string;
    sendingConfirmation: boolean;

    isValidated: boolean;
}

export class ICustomAuthComponent extends React.Component<ICustomAuthComponentProps, ICustomAuthComponentState> {
    private refReCaptchaRegister: React.RefObject<ReCAPTCHA> = null;
    private refReCaptchaLogin: React.RefObject<ReCAPTCHA> = null;

    constructor(props: ICustomAuthComponentProps) {
        super(props);

        this.refReCaptchaRegister = React.createRef<ReCAPTCHA>();
        this.refReCaptchaLogin = React.createRef<ReCAPTCHA>();

        //let stringUser = sessionStorage.getItem("CurrentUser");


        let loggedIn = false;
        let user: IUserAccount = {
            firstName: "",
            lastName: "",
            email: "",
            accountType: AccountType.Custom,
            password: "",
            userId: ""
        }
        //if (stringUser) {
        //    user = JSON.parse(stringUser) as IUserAccount;
        //    loggedIn = true;
        //    this.props.setAuthStatus(true, user);
        //}

        if (props.currentUser) {
            user = props.currentUser;
            loggedIn = true;
        }

        this.state = {
            state: "login",
            currentUser: user,
            firstNameErrorMessage: "",
            lastNameErrorMessage: "",
            emailErrorMessage: "",
            passwordErrorMessage: "",
            confirmPasswordErrorMessage: "",
            legalNoticeErrorMessage: "",
            privacyNoticeErrorMessage: "",

            pwdBadgeVariant: null,
            pwdBadgeLabel: null,

            registered: false,

            loggedIn: loggedIn,

            legalNoticeChecked: false,
            privacyNoticeChecked: false,

            isProcessing: false,
            showCustomLoginPanel: false,
            reCaptchaError: false,
            reCaptchaToken: null,

            sendingConfirmation: false,
            isValidated: false
        };

    }
    public componentDidMount() {
        //console.log("LoginComponent componentDidMount");

    }

    public componentWillReceiveProps(newProps: ICustomAuthComponentProps) {
        if (this.props.currentUser != newProps.currentUser) {
            let user: IUserAccount = newProps.currentUser;
            if (!newProps.currentUser) {
                user = {
                    firstName: "",
                    lastName: "",
                    email: "",
                    accountType: AccountType.Custom,
                    password: "",
                    userId: ""
                }
            }
            this.setState({
                loggedIn: Boolean(newProps.currentUser),
                currentUser: user
            });
        }
    }

    private _onRegisterBtnCLicked = () => {
        try {
            console.time("_onRegisterBtnCLicked");
            if (this.inputsAreaValid(true)) {
                // check if email allready exist                
                this.registerUser();

            } else {
                this.setInformationMessage("Please fix the validation issues", MessageBarType.error);
            }
        } catch (err) {
            alert("Error: " + err);
        }
    }
    private _onLoginBtnClicked = () => {
        try {
            console.time("_onLoginBtnClicked");
            if (this.inputsAreaValid(false)) {
                // check if email allready exist
                this.loginUser();
            } else {
                this.setInformationMessage("Please fix the validation issues", MessageBarType.error);
            }
        } catch (err) {
            alert("Error: " + err);
        }
    }
    private _onLogoutBtnClicked = () => {
        try {
            console.time("_onLogoutBtnClicked");
            this.setState({
                currentUser: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    accountType: AccountType.Custom,
                    password: "",
                    userId: ""
                },
                registered: false, loggedIn: false
            }, () => {
                this.props.setAuthStatus(false, null);
            });

        } catch (err) {
            alert("Error: " + err);
        } finally {
            window.sessionStorage.removeItem("authToken");
            window.sessionStorage.removeItem("freelanceUser");
        }
    }

    private resendActivationCode = () => {
        this.setState({ sendingConfirmation: true });
        let updatedMessage: any = <>Account is not active<br /><Stack horizontal tokens={{ childrenGap: 10 }}><a href="#" onClick={() => { }}>Resent Confirmation Email</a> <Spinner /></Stack></>;
        this.setInformationMessage(updatedMessage, MessageBarType.error, false)
        let baseUrl = Common.getCurrentServerUrl();
        this.props.dataProvider.resendActivationCode(this.state.currentUser.email, baseUrl).then(() => {
            console.log("Resent activation code");
            this.setInformationMessage("Confirmation Mail was sent successfully", MessageBarType.success);
        }).catch(err => {
        }).finally(() => {
            this.setState({ sendingConfirmation: false });
        });
    }

    private loginUser = () => {
        this.setState({ isProcessing: true, informationMessage: "" });
        this.props.dataProvider.loginInternalUserAccount(this.state.currentUser, this.state.reCaptchaToken).then(user => {
            console.log("Custom User logged in", user);

            window.sessionStorage.setItem("authToken", JSON.stringify(user.bearerToken));
            window.sessionStorage.setItem("freelanceUser", JSON.stringify(user));

            this.setInformationMessage("Login Successful", MessageBarType.success);
            this.setState({ loggedIn: true });
            /*sessionStorage.setItem("CurrentUser", JSON.stringify(user));*/
            this.setState({ currentUser: user });
            this.props.setAuthStatus(true, user);

            if (this.props.onPanelStatusChange) {
                this.props.onPanelStatusChange(false);
            }

        }).catch(err => {
            console.dir(err);

            window.sessionStorage.removeItem("authToken");
            window.sessionStorage.removeItem("freelanceUser");
            let autoDismissError = true;
            let errormessage = err;
            if (err.detail) {
                errormessage = err.detail;
            }

            if (errormessage == "Account is not active") {
                // augment error with confirmation hyperlink
                errormessage = <>Account is not active<br /><a href="#" onClick={this.resendActivationCode}>Resent Confirmation Email</a></>;
                autoDismissError = false;
            } else if (errormessage == "reCaptcha Token expired or is invalid") {
                errormessage = <>reCaptcha Token has expired. Please try again.</>;
                autoDismissError = false;
            }

            this.setInformationMessage(errormessage, MessageBarType.error, autoDismissError);

            // reset reCaptcha, it's only valid for 1 serverVerify
            this.refReCaptchaLogin.current.reset();

        }).finally(() => {
            this.setState({ isProcessing: false });
        });
    }



    private registerUser = () => {
        this.setState({ isProcessing: true });
        this.props.dataProvider.registerInternalUserAccount(this.state.currentUser, this.state.reCaptchaToken).then(result => {
            //this.setInformationMessage("New Account Register Successfully, please click on the link in the email to confirm the registration", MessageBarType.success);
            this.setState({ registered: true });
        }).catch(err => {
            console.dir(err);
            let errormessage = err;
            if (err.detail) {
                errormessage = err.detail;
            } else if (err.status) {
                console.log(err.status)
                errormessage = GeneralHelper.parseError(err);
            }
            if (errormessage == "reCaptcha Token expired or is invalid") {
                errormessage = <>reCaptcha Token has expired. Please try again.</>;
            }
            this.setInformationMessage(errormessage, MessageBarType.error);

            // reset reCaptcha, it's only valid for 1 serverVerify
            this.refReCaptchaRegister.current.reset();

        }).finally(() => {
            this.setState({ isProcessing: false });
        });
    }

    private onReCaptchaChange = (value: string) => {
        console.log("ReCaptcha value: " + value)
    };

    private inputsAreaValid = (register: boolean): boolean => {

        this.setState({ firstNameErrorMessage: "", lastNameErrorMessage: "", emailErrorMessage: "", passwordErrorMessage: "", confirmPasswordErrorMessage: "", legalNoticeErrorMessage: "", privacyNoticeErrorMessage: "", reCaptchaError: false });
        let invalid = false;

        if (register) {
            if (!this.state?.currentUser?.firstName || this.state?.currentUser?.firstName?.trim().length === 0) {
                invalid = true;
                this.setState({ firstNameErrorMessage: GeneralHelper.mandatoryValidation });
            }
            if (!this.state?.currentUser?.lastName || this.state?.currentUser?.lastName?.trim().length === 0) {
                invalid = true;
                this.setState({ lastNameErrorMessage: GeneralHelper.mandatoryValidation });
            }
            if (!this.state?.currentUser?.password || this.state?.currentUser?.password?.trim().length === 0) {
                invalid = true;
                this.setState({ passwordErrorMessage: GeneralHelper.mandatoryValidation });
            } else {
                if (this.state?.confirmationPassword != this.state?.currentUser?.password) {
                    invalid = true;
                    this.setState({ confirmPasswordErrorMessage: GeneralHelper.confirmationPasswordInvalid });
                }
            }
            if (!this.checkPasswordStrength(this.state?.currentUser?.password)) {
                invalid = true;
                // no errorMessage necessary, it's displayed already with the password strength badge
            }
            if (!this.state?.legalNoticeChecked) {
                invalid = true;
                this.setState({ legalNoticeErrorMessage: "You must confirm to have read and agree to StraumannGroup's Legal Notice" });
            }
            if (!this.state?.privacyNoticeChecked) {
                invalid = true;
                this.setState({ privacyNoticeErrorMessage: "You must confirm to have read and agree to StraumannGroup's Privacy Notice" });
            }

            if (!this.refReCaptchaRegister?.current?.getValue()) {
                invalid = true;
                this.setState({ reCaptchaError: true });
            }
        } else {
            if (!this.refReCaptchaLogin?.current?.getValue()) {
                invalid = true;
                this.setState({ reCaptchaError: true });
            }
        }

        if (!this.state?.currentUser?.email || this.state?.currentUser?.email?.trim().length == 0) {
            invalid = true;
            this.setState({ emailErrorMessage: GeneralHelper.mandatoryValidation });
        } else if (this.state?.currentUser?.email?.indexOf("@") == -1) {
            invalid = true;
            this.setState({ emailErrorMessage: GeneralHelper.emailValidation });
        }
        if (!this.state?.currentUser?.password || this.state?.currentUser?.password?.trim().length === 0) {
            invalid = true;
            this.setState({ passwordErrorMessage: GeneralHelper.mandatoryValidation });
        }

        this.setState({ isValidated: true });

        return !invalid;
    }

    private resetValidation = (): void => {
        this.setState({
            firstNameErrorMessage: "", lastNameErrorMessage: "", emailErrorMessage: "", passwordErrorMessage: "", confirmPasswordErrorMessage: "", informationMessage: "",
            legalNoticeErrorMessage: "", privacyNoticeErrorMessage: "", pwdBadgeVariant: null, pwdBadgeLabel: null, reCaptchaError: false, isValidated: false
        });

        this.setState(prevState => ({
            currentUser: {
                ...prevState.currentUser,
                email: null,
                password: null
            }
        }));
    };

    private setInformationMessage = (message: string, type?: MessageBarType, autoDismiss: boolean = true): void => {
        if (autoDismiss) {
            this.setState(
                {
                    informationMessage: message,
                    informationType: type || MessageBarType.warning
                },
                () => {
                    setTimeout(() => { this.setState({ informationMessage: "" }); }, 4000)
                });
        } else {
            this.setState({ informationMessage: message, informationType: type || MessageBarType.warning });
        }
    }

    private onCustomLoginButton_Clicked = (): void => {
        this.setState({ showCustomLoginPanel: true });

        if (this.props.onPanelStatusChange) {
            this.props.onPanelStatusChange(true);
        }
    }

    private onCustomLoginPanel_Dismiss = (): void => {
        this.setState({ showCustomLoginPanel: false });

        if (this.props.onPanelStatusChange) {
            this.props.onPanelStatusChange(false);
        }

        this.resetValidation();
    }

    private onLegalNoticeClicked = (ev?: any, checked?: boolean) => {
        this.setState({
            legalNoticeChecked: checked
        }, () => {
            if (this.state.isValidated) {
                this.inputsAreaValid(true);
            }
        });
    }

    private onPrivacyNoticeClicked = (ev?: any, checked?: boolean) => {
        this.setState({
            privacyNoticeChecked: checked
        }, () => {
            if (this.state.isValidated) {
                this.inputsAreaValid(true);
            }
        });
    }

    private checkPasswordStrength = (pwd: string): boolean => {
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
        let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
        let badgeVariant = null;
        let badgeLabel = null;
        let result: boolean = true;
        if (strongPassword.test(pwd)) {
            badgeVariant = "success";
            badgeLabel = "Strong Password";
            result = true;
        } else if (mediumPassword.test(pwd)) {
            badgeVariant = "warning";
            badgeLabel = "Too short";
            result = false;
        } else {
            badgeVariant = "danger";
            badgeLabel = "Weak Password";
            result = false;
        }
        this.setState({
            pwdBadgeVariant: badgeVariant,
            pwdBadgeLabel: badgeLabel
        });
        return result;
    }

    public render(): React.ReactElement<ICustomAuthComponentProps> {

        let userData = (this.state.currentUser) as any;

        const styleError: string = mergeStyles({
            fontFamily: 'Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(164, 38, 44)',
            margin: '0px',
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center'
        });

        let userName = this.props.currentUser ? `${this.props.currentUser.firstName} ${this.props.currentUser.lastName}` : "";
        let currentUser = window.sessionStorage.freelanceUser ? JSON.parse(window.sessionStorage.freelanceUser) : null;
        userName = currentUser?.displayName ? currentUser.displayName : userName;

        return (
            <div >
                <div>
                    {
                        this.state.registered ? <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={true}
                            dismissIconProps={{ hidden: false, iconName: 'ChromeClose' }}
                            dismissButtonAriaLabel="Close"
                            onDismiss={() => {
                                this.setState({ registered: false });
                                if (this.props.onPanelStatusChange) {
                                    this.props.onPanelStatusChange(false);
                                }
                            }}
                        >You have been registered successfully.<br />E-Mail Validation is required to activate your account.<br /><br /><span style={{ fontWeight: 'bold' }}>Please check your E-Mail for a validation E-Mail.</span></MessageBar> :
                            <> {
                                this.state.loggedIn ? <div>Signed in as:<br /><span className='loginDisplayName'>{`${userName}`}</span><br /><Button variant="secondary" className="customButton" style={{ width: '120px', marginTop: '10px' }} onClick={this._onLogoutBtnClicked}>Logout</Button></div> :
                                    <>
                                        {
                                            !this.state.showCustomLoginPanel ? <Button style={{ width: '200px', fontSize: '14px', fontWeight: 500, fontFamily: 'Roboto, sans-serif' }} variant="secondary" className="customButton" onClick={this.onCustomLoginButton_Clicked}>Sign in or create new account</Button>
                                                :
                                                <fieldset style={{ border: '1px solid #ddd', backgroundColor: 'white', padding: '20px', position: 'relative', marginTop: '25px', borderRadius: '5px', boxShadow: '1px 3px 2px 0px rgb(0, 0, 0, 0.15)' }}>
                                                    <legend style={{ fontSize: '13px', fontWeight: 'bold' }}>Straumann Research Screening Account</legend>
                                                    <IconButton styles={{ root: { position: 'absolute', top: '5px', right: '5px' } }} iconProps={{ iconName: 'ChromeClose' }} onClick={this.onCustomLoginPanel_Dismiss} />
                                                    <Tabs onSelect={this.resetValidation} >
                                                        <TabList>
                                                            <Tab>Sign in</Tab>
                                                            <Tab>Register</Tab>
                                                        </TabList>
                                                        <TabPanel>
                                                            <TextField label="E-Mail Address" autoComplete="off" placeholder="Enter your mail" errorMessage={this.state.emailErrorMessage}
                                                                onChange={(event, newValue?) => {
                                                                    this.setState(prevState => ({
                                                                        currentUser: {
                                                                            ...prevState.currentUser,
                                                                            email: newValue || ""
                                                                        },
                                                                        emailErrorMessage: ""
                                                                    }), () => {
                                                                        if (this.state.isValidated) { this.inputsAreaValid(false); }
                                                                    });
                                                                }} defaultValue={this.state?.currentUser?.email}></TextField>
                                                            <TextField label="Password" type="password" autoComplete="off" canRevealPassword placeholder="Enter your password" errorMessage={this.state.passwordErrorMessage}
                                                                onChange={(event, newValue?) => {
                                                                    this.setState(prevState => ({
                                                                        currentUser: {
                                                                            ...prevState.currentUser,
                                                                            password: newValue || ""
                                                                        },

                                                                    }), () => {
                                                                        if (this.state.isValidated) { this.inputsAreaValid(false); }
                                                                    });
                                                                }} defaultValue={this.state?.currentUser?.password}></TextField>
                                                            <Stack style={{ border: this.state.reCaptchaError ? '1px solid red' : 'none', padding: '5px' }}>
                                                                <ReCAPTCHA
                                                                    ref={this.refReCaptchaLogin}
                                                                    sitekey="6LeuVHYgAAAAANhF9ul_Zmp82N78ul1BCUeEm29A"
                                                                    onChange={(token: string) => { this.setState({ reCaptchaToken: token }); if (this.state.isValidated) { this.inputsAreaValid(false); } }}
                                                                />
                                                                {this.state.reCaptchaError && <Label className={css(styleError, 'freelanceError')}>Please click on "I'm not a robot"</Label>}
                                                            </Stack>
                                                            <div style={{ display: 'flex', gap: '10px' }}><DefaultButton disabled={this.state.isProcessing} className={"customButton"} onClick={this._onLoginBtnClicked}>Login</DefaultButton>{this.state.isProcessing && <Spinner />}</div><br />
                                                            {
                                                                this.state.informationMessage ?
                                                                    <MessageBar
                                                                        //className={styles.messageBar}
                                                                        messageBarType={this.state.informationType || MessageBarType.warning}
                                                                        isMultiline={false}
                                                                        dismissButtonAriaLabel="Close"
                                                                    >{this.state.informationMessage}</MessageBar> : ""
                                                            }
                                                            <a style={{ marginTop: '5px', display: 'block', fontSize: '14px' }} href="#" onClick={() => { window.location.href = `${Common.getCurrentServerUrl()}/resetpw` }}  >Forgot my password</a>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <TextField label="Firstname" placeholder="Enter your firstname" errorMessage={this.state.firstNameErrorMessage}
                                                                onChange={(event, newValue?) => {
                                                                    this.setState(prevState => ({
                                                                        currentUser: {
                                                                            ...prevState.currentUser,
                                                                            firstName: newValue || "",

                                                                        },
                                                                        firstNameErrorMessage: ""
                                                                    }), () => {
                                                                        if (this.state.isValidated) { this.inputsAreaValid(true); }
                                                                    });
                                                                }} defaultValue={this.state.currentUser.firstName}></TextField>
                                                            <TextField label="Lastname" placeholder="Enter your lastname" errorMessage={this.state.lastNameErrorMessage}
                                                                onChange={(event, newValue?) => {
                                                                    this.setState(prevState => ({
                                                                        currentUser: {
                                                                            ...prevState.currentUser,
                                                                            lastName: newValue || ""
                                                                        },
                                                                        lastNameErrorMessage: ""
                                                                    }), () => {
                                                                        if (this.state.isValidated) { this.inputsAreaValid(true); }
                                                                    });
                                                                }} defaultValue={this.state?.currentUser?.lastName}></TextField>
                                                            <TextField label="E-Mail Address" autoComplete="off" placeholder="Enter your mail" errorMessage={this.state.emailErrorMessage}
                                                                onChange={(event, newValue?) => {
                                                                    this.setState(prevState => ({
                                                                        currentUser: {
                                                                            ...prevState.currentUser,
                                                                            email: newValue || ""
                                                                        },
                                                                        emailErrorMessage: ""
                                                                    }), () => {
                                                                        if (this.state.isValidated) { this.inputsAreaValid(true); }
                                                                    });
                                                                }} defaultValue={this.state?.currentUser?.email}></TextField>
                                                            <TextField label="Password" type="password" autoComplete="off" canRevealPassword placeholder="Enter your password" errorMessage={this.state.passwordErrorMessage}
                                                                onChange={(event, newValue?) => {
                                                                    this.setState(prevState => ({
                                                                        currentUser: {
                                                                            ...prevState.currentUser,
                                                                            password: newValue || ""
                                                                        }

                                                                    }), () => {
                                                                        if (this.state.isValidated) { this.inputsAreaValid(true); }
                                                                    });

                                                                    this.checkPasswordStrength(newValue);

                                                                }} defaultValue={this.state?.currentUser?.password}></TextField>
                                                            {this.state?.pwdBadgeVariant && this.state?.pwdBadgeLabel && <Stack style={{ marginTop: '5px' }}><Badge variant={this.state.pwdBadgeVariant} style={{ width: '100px' }}>{this.state.pwdBadgeLabel}</Badge>{(this.state?.pwdBadgeVariant == "danger" || this.state?.pwdBadgeVariant == "warning") && <Label style={{ color: 'red', maxWidth: '410px' }}>Password must be at least 8 characters long and contain: lowercase letters, capital letters, numbers and special characters</Label>}</Stack>}
                                                            <TextField label="Confirm Password" type="password" autoComplete="off" canRevealPassword placeholder="Confirm Password" errorMessage={this.state.confirmPasswordErrorMessage}
                                                                onChange={(event, newValue?) => {
                                                                    this.setState({ confirmationPassword: newValue }, () => {
                                                                        if (this.state.isValidated) { this.inputsAreaValid(true); }
                                                                    });
                                                                }} defaultValue={this.state?.confirmationPassword}></TextField>
                                                            <Stack style={{ marginTop: '15px' }}>
                                                                <Stack horizontal verticalAlign='center'>
                                                                    <Checkbox id="chkLegal" onChange={this.onLegalNoticeClicked} checked={this.state?.legalNoticeChecked} />
                                                                    <Label htmlFor="chkLegal" style={{ position: 'relative', top: '-5px' }}>I agree to the <a target="_blank" href="https://www.straumann.com/en/dental-professionals/legal-notice.html">StraumannGroup's Legal Notice</a></Label>
                                                                </Stack>
                                                                {this.state.legalNoticeErrorMessage && <Label className={css(styleError, 'freelanceError')}>{this.state.legalNoticeErrorMessage}</Label>}
                                                            </Stack>
                                                            <Stack >
                                                                <Stack horizontal verticalAlign='center'>
                                                                    <Checkbox id="chkPrivacy" onChange={this.onPrivacyNoticeClicked} checked={this.state?.privacyNoticeChecked} />
                                                                    <Label htmlFor="chkPrivacy" style={{ position: 'relative', top: '5px', maxWidth: '365px' }}>I acknowledge that my data will be processed in accordance with the <a target="_blank" href="https://www.straumann.com/group/en/discover/privacy/com/en.html">StraumannGroup's Privacy Notice</a></Label>
                                                                </Stack>
                                                                {this.state?.privacyNoticeErrorMessage && <Label className={css(styleError, 'freelanceError')}>{this.state.privacyNoticeErrorMessage}</Label>}
                                                            </Stack>
                                                            <Stack style={{ border: this.state.reCaptchaError ? '1px solid red' : 'none', padding: '5px', marginTop: '15px' }}>
                                                                <ReCAPTCHA
                                                                    ref={this.refReCaptchaRegister}
                                                                    sitekey="6LeuVHYgAAAAANhF9ul_Zmp82N78ul1BCUeEm29A"
                                                                    onChange={(token: string) => { this.setState({ reCaptchaToken: token }); if (this.state.isValidated) { this.inputsAreaValid(true); } }}
                                                                />
                                                                {this.state.reCaptchaError && <Label className={css(styleError, 'freelanceError')}>Please click on "I'm not a robot"</Label>}
                                                            </Stack>
                                                            {
                                                                this.state.informationMessage ?
                                                                    <MessageBar
                                                                        //className={styles.messageBar}
                                                                        messageBarType={this.state.informationType || MessageBarType.warning}
                                                                        isMultiline={false}
                                                                        dismissButtonAriaLabel="Close"
                                                                    >{this.state.informationMessage}</MessageBar> : ""
                                                            }
                                                            <div style={{ display: 'flex', gap: '10px' }}><DefaultButton disabled={this.state.isProcessing} className={"customButton"} onClick={this._onRegisterBtnCLicked}>Register</DefaultButton>{this.state.isProcessing && <Spinner />}</div>
                                                        </TabPanel>
                                                    </Tabs>
                                                </fieldset>
                                        }

                                    </>
                            }</>
                    }

                </div>
            </div>
        );
    }
}

