import { Label, Stack, StackItem } from 'office-ui-fabric-react';
import * as React from 'react';
import { AppConstants } from '../../../../Utility/Constants';
import '../Calendar.scss';
import { EventMail } from './EventMail';
import { RenderApplicant } from './RenderApplicant';
export interface IEventApplicantProps {
    eventRequiredJobs: any,
    eventSubmissions: any,
    event: any
}

export interface IEventApplicantState {
}

export class EventApplicant extends React.Component<IEventApplicantProps, IEventApplicantState> {
    constructor(MyProps: any) {
        super(MyProps);
        this.state = {
        };
    }

    public render() {

        return (
            <Stack className='applicantRegSection'>
                <Stack.Item className='lblPart'>
                    <Label>Registered Users <EventMail event={this.props.event}
                        eventRequiredJobs={this.props.eventRequiredJobs}
                        eventSubmissions={this.props.eventSubmissions}
                    /></Label>
                </Stack.Item>
                <RenderApplicant
                    eventRequiredJobs={this.props.eventRequiredJobs}
                    eventSubmissions={this.props.eventSubmissions}
                    returnSelection={null}
                />
                {/* <StackItem className='eventApplicantSec'>
                    <Stack horizontal className='eventApplicant'>
                        {
                            this.state.eventJobSubmissions.map((job: any) => {
                                return <Stack.Item className='eventJob' key={"job" + job.title}>
                                    <Stack className='eventJobDiv'>
                                        <Stack.Item className='jobTitle'>
                                            {job.title}
                                        </Stack.Item>
                                        <Stack.Item className='jobApplicant'>
                                            {job.Applicant.map((applicant: any) => {
                                                return <div key={"jobApplicant" + applicant.Name} className='applicantName'><span className='name'>{applicant.Name}</span><span className='type'>{applicant.Status + " " + applicant.Order}</span></div>
                                            })}
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>

                            })
                        }

                    </Stack>
                </StackItem> */}
            </Stack>
        );
    }

    public componentDidMount() {

    }

}