import { Dialog, DialogType, Spinner } from 'office-ui-fabric-react';
import * as React from 'react';
export interface IFormLoaderProps {
    ShowLoader: any;
    SpinnerSize: number;
    SpinnerLabel: string;
    DialogType: DialogType;
}

export interface IFormLoaderState {
}


export class FormLoader extends React.Component<IFormLoaderProps, IFormLoaderState> {
    constructor(MyProps: any) {
        super(MyProps);
        this.state = {
        };
    }


    public render() {

        return (


            <Dialog
                hidden={!this.props.ShowLoader}
                dialogContentProps={{
                    type: this.props.DialogType,
                }}
                modalProps={{ isBlocking: true, className: "busyOverlay" }}
            >
                <Spinner size={this.props.SpinnerSize}
                    label={this.props.SpinnerLabel} ariaLive="assertive" />
            </Dialog>
        );
    }
    public componentDidMount() {
    }

}