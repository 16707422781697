import * as React from 'react';
import {
    ActionButton, Checkbox, css, Stack, PrimaryButton, TextField, Text, getTheme, Link, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, IDetailsRowProps, DetailsRow, Spinner, IconButton, Dialog, DialogFooter, DefaultButton, ConstrainMode, Icon, Label, DialogType, SpinnerSize, Dropdown, IDropdownOption, FontIcon, IIconProps, Toggle
} from 'office-ui-fabric-react';
import './UserProfile.scss';
import { AttachmentComponent } from '../../../AttachmentComponent/AttachmentComponent';
import { observer } from 'mobx-react';
import { IDataProvider } from '../../../../dataprovider/IDataProvider';
import { IEventQuery, IUserJobs, IUserSubmitProfile } from '../../../../Interfaces/IUserDetails';
import { CommentMode, ProfileView } from '../../../Utility/FreelancerEnums';
import { FreelancerContext } from '../../Common/FreelancerContext';
import { AppConstants } from '../../../Utility/Constants';
import { AppTooltip } from '../../Common/AppTooltip';
import { ExportToCsv, Options } from 'export-to-csv';
import { Rating } from '@fluentui/react';
import { Utility } from '../../../Utility/Utility';
import { Constants } from '@azure/msal-common';
import { ModalComment } from '../../Common/ModalComment';
import { IFileItem } from '../../../../Interfaces/IFileItem';
export interface IDefaultViewProps {
    dataProvider: IDataProvider;
    ViewType: ProfileView;
    UserDetail: any;
    MasterData: any;
    IsCustomAccount: boolean;
    ReloadLoginProfile: any;
    ClosePanel?: any;
}

export interface IDefaultViewState {
    isCommentDialogVisible: boolean;
    currentUserDetail: any;
    cvAttachments: Array<IFileItem | File>;
    deletedAttachments: Array<IFileItem | File>;
    userChoices: any;
    adminChoices: any;

    IsCVModified: boolean;
    IsAccountUpdateRequired: boolean;
    options: IDropdownOption[];
    selectedStatus: any,
    IsRejectReasonVisible: boolean;
    IsDisabled: boolean;
}
let isSubmitVisible = false;
let isAttachmentSectionVisible = false;
let isAdminChoicesVisible = false;
let isJobOptionVisible = false;
let userRating: number = 0;
let userEventsForDownload: any = [];
let userAdminComments: any = [];
const eventDownloadIcon: IIconProps = { iconName: 'Download' };
const passwordIcon: IIconProps = { iconName: 'PasswordField' };
const options: Options = {
    filename: "All events",
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
};
const csvExporter = new ExportToCsv(options);
export class UserProfile extends React.Component<IDefaultViewProps, IDefaultViewState> {
    constructor(MyProps: IDefaultViewProps) {
        super(MyProps);
        var cvAttachments: Array<IFileItem | File>;
        if (this.props.UserDetail && this.props.UserDetail.cv?.name) {
            cvAttachments = [{
                docType: "CV",
                isDeleted: false,
                name: this.props.UserDetail.cv.name,
                serverRelativeUrl: this.props.UserDetail.cv.url,
                uniqueId: this.props.UserDetail.cv.attachItemID
            }];
        }
        else {
            cvAttachments = null;
        }

        /// calculate options
        Utility.removeEmptyDivIfPresent();
        if (this.props.ViewType == ProfileView.AdminUser) {
            Utility.addBlockBackgroundClass();
        }
        // this.context.ShowFreelanceLoader(true, "");
        var availableOptions: IDropdownOption[] = [];
        if (this.props.MasterData) {
            this.props.MasterData.userStatusColl.forEach((element: string) => {
                availableOptions.push({
                    key: element,
                    text: element
                });
            });
        }
        var availableJobs = JSON.parse(JSON.stringify(this.props.MasterData?.userJobArea));
        var userSelection: any = [];
        if (this.props.UserDetail.appliedJobs) {
            userSelection = JSON.parse(JSON.stringify(this.props.UserDetail.appliedJobs));
        }
        availableJobs.forEach((job: any) => {
            var selectedItems = [];
            selectedItems = userSelection.filter((item: any) => {
                return (item.lookupId == job.id);
            });
            if (selectedItems.length > 0) {
                job.Selected = true;
            }
            else {
                job.Selected = false;
            }
        });

        var adminAvailableJobs = JSON.parse(JSON.stringify(this.props.MasterData?.userJobArea));
        var adminSelection: any = [];
        if (this.props.UserDetail.approvedJobs) {
            adminSelection = JSON.parse(JSON.stringify(this.props.UserDetail.approvedJobs));
        }
        adminAvailableJobs.forEach((job: any) => {
            var selectedItems = [];
            selectedItems = adminSelection.filter((item: any) => {
                return (item.lookupId == job.id);
            });
            if (selectedItems.length > 0) {
                job.Selected = true;
            }
            else {
                job.Selected = false;
            }
        });
        ///
        this.state = {
            isCommentDialogVisible: false,
            IsDisabled: this.props.ViewType != ProfileView.User ? true : false,
            IsRejectReasonVisible: this.props.UserDetail?.status ? (this.props.UserDetail?.status == AppConstants.ProfileStatus.Rejected ? true : false) : false,
            IsAccountUpdateRequired: false,
            cvAttachments: cvAttachments,
            currentUserDetail: JSON.parse(JSON.stringify(this.props.UserDetail)),
            deletedAttachments: [],
            userChoices: availableJobs,
            adminChoices: adminAvailableJobs,
            IsCVModified: false,
            options: availableOptions,
            selectedStatus: this.props.UserDetail?.status ? this.props.UserDetail?.status : ""
        };
        this.closeCommentDialog = this.closeCommentDialog.bind(this);
        this.onCVAttachmentUploadAdded = this.onCVAttachmentUploadAdded.bind(this);
        this.onCVAttachmentDeleted = this.onCVAttachmentDeleted.bind(this);
        this.onSaveUserProfile = this.onSaveUserProfile.bind(this);
        this.isAttachmentSectionVisible();
        this.isSubmitVisible();
        this.isJobOptionVisible();
        this.isAdminChoicesVisible();
    }

    private onCVAttachmentUploadAdded = (files: File[]): Promise<void> => {
        let delayedAttachments: Array<IFileItem | File> = [];
        if (this.state.cvAttachments)
            delayedAttachments = [...this.state.cvAttachments];
        files.forEach((file) => {
            delayedAttachments.push(file);
        });
        this.setState({
            cvAttachments: delayedAttachments,
            IsCVModified: true
        });
        return Promise.resolve();
    }
    private onCVAttachmentDeleted = async (file: IFileItem | File): Promise<void> => {
        if (file instanceof File) {
            this.setState({
                cvAttachments: null
            });
        } else {
            file.isDeleted = true;
            let deletedAttachments = [...this.state.deletedAttachments];
            deletedAttachments.push(file);
            this.setState({
                cvAttachments: null,
                deletedAttachments: deletedAttachments,
                IsCVModified: true
            });
        }
        return Promise.resolve();
    }
    private onSaveUserProfile() {
        if (this.props.ViewType == ProfileView.AdminUser
            && this.state.selectedStatus == AppConstants.ProfileStatus.InProgress) {
            if (this.props.UserDetail?.status == AppConstants.ProfileStatus.Active && this.state.selectedStatus == AppConstants.ProfileStatus.InProgress) {
                this.context.ShowFreelancerModal("Data validation alert", "Admin can't change 'Active' profile to 'In Progress' state.");
                return false;
            }
        }

        if (this.props.ViewType == ProfileView.AdminUser && this.state.selectedStatus != AppConstants.ProfileStatus.InProgress) {
            var selectedItems = this.state.adminChoices.filter((item: any) => {
                return (item.Selected == true);
            });

            if (this.state.selectedStatus == AppConstants.ProfileStatus.Submitted) {
                this.context.ShowFreelancerModal("Data validation alert", "Please select status other than 'Submitted'.");
                return false;
            }
            if (selectedItems.length == 0) {
                this.context.ShowFreelancerModal("Data validation alert", "Please select admin confirmation.");
                return false;
            }
            if (this.state.IsRejectReasonVisible) {
                var reason = this.state.currentUserDetail?.reason;
                if (reason) {

                }
                else {
                    this.context.ShowFreelancerModal("Data validation alert", "Please enter reason.");
                    return false;
                }
            }
        }

        var submitStatus = this.props.UserDetail?.status;
        if (this.props.ViewType == ProfileView.User
            &&
            (
                this.props.UserDetail?.status == AppConstants.ProfileStatus.Submitted
                || this.props.UserDetail?.status == AppConstants.ProfileStatus.Rejected
                || this.props.UserDetail?.status == undefined
                || this.props.UserDetail?.status == null
            )
        ) {
            submitStatus = AppConstants.ProfileStatus.Submitted;
            if (this.state.cvAttachments == null || this.state.cvAttachments.length == 0) {
                this.context.ShowFreelancerModal("Data validation alert", "Please upload CV.");
                return false;
            }
            var selectedItems = this.state.userChoices.filter((item: any) => {
                return (item.Selected == true);
            });
            if (selectedItems.length == 0) {
                this.context.ShowFreelancerModal("Data validation alert", "Please select job options.");
                return false;
            }
        }


        // validate form
        this.context.ShowFreelanceLoader(true, "Please wait. Data save is in progress.");
        return new Promise((resolve, reject) => {
            var userSubmitValues: IUserSubmitProfile = {
                userAccountId: this.props.UserDetail?.userAccountId ? this.props.UserDetail.userAccountId : 0,
                ProfileId: this.state.currentUserDetail ? this.state.currentUserDetail.id : null,
                FirstName: this.state.currentUserDetail.firstName,
                LastName: this.state.currentUserDetail.lastName,
                Email: this.state.currentUserDetail.title,
                Telephone: this.state.currentUserDetail.mobileNo,
                AccountType: this.state.currentUserDetail.accountType,
                IsAccountUpdateRequired: this.state.IsAccountUpdateRequired,
                Choices: [],
                AdminChoices: [],
                Notifications: this.state.currentUserDetail?.notifications ? this.state.currentUserDetail?.notifications : false,
                Reason: this.state.currentUserDetail?.reason
                    ? (this.props.ViewType == ProfileView.User
                        ? ""
                        : this.state.currentUserDetail?.reason)
                    : "",
                Status: this.props.ViewType == ProfileView.User
                    ? submitStatus
                    : this.state.selectedStatus
            };

            /// push user jobs
            this.state.userChoices.forEach((choice: any) => {
                if (choice.Selected) {
                    userSubmitValues.Choices.push({
                        Id: choice.id,
                        Value: choice.title
                    });
                }
            });
            /// push admin selected jobs
            this.state.adminChoices.forEach((choice: any) => {
                if (choice.Selected) {
                    userSubmitValues.AdminChoices.push({
                        Id: choice.id,
                        Value: choice.title
                    });
                }
            });
            if (Utility.IsLoginUserActive(this.context.ExpireSession)) {
                this.props.dataProvider.submitUserProfile(userSubmitValues)
                    .then(async (result: any) => {
                        // console.log(result);
                        if (!result.hasError) {
                            /// Update display name on form
                            try {
                                if (this.state.IsAccountUpdateRequired == true
                                    && this.props.ViewType == ProfileView.User
                                ) {
                                    let currentUser = JSON.parse(window.sessionStorage.freelanceUser);
                                    currentUser.displayName = this.state.currentUserDetail.firstName + " " + this.state.currentUserDetail.lastName;
                                    window.sessionStorage.setItem("freelanceUser", JSON.stringify(currentUser));
                                }
                            } catch (error) { }

                            if (this.state.IsCVModified) {
                                this.props.dataProvider.uploadCVAsAttachment(result.data.profileId, [...this.state.cvAttachments])
                                    .then((cvUploadResult) => {
                                        resolve("Success");
                                        if (!cvUploadResult.hasError) {
                                            this.context.ShowFreelancerModal("Message", "Profile updated successfully.");
                                            if (this.props.ClosePanel) {
                                                this.props.ClosePanel();
                                            }
                                            else {
                                                this.props.ReloadLoginProfile();
                                            }
                                        }
                                        else {
                                            console.log("Error in uploading doc. " + cvUploadResult.errorDetail);
                                            this.context.ShowFreelancerModal("Error", cvUploadResult.message);
                                            this.context.ShowFreelanceLoader(false, "");
                                        }
                                    }).catch(error1 => {
                                        console.log("Error in calling upload document service");
                                        console.log(error1);
                                        resolve("fail");
                                        this.context.ShowFreelanceLoader(false, "");
                                        this.context.ShowFreelancerModal("Error", "Error in saving user profile CV.");
                                        this.setState({
                                            cvAttachments: []
                                        });
                                    });
                            }
                            else {

                                if (this.props.ClosePanel) {
                                    this.context.ShowFreelancerModalWithFun("Message", "Profile updated successfully.", this.props.ClosePanel);
                                    // this.props.ClosePanel();
                                }
                                else {
                                    this.context.ShowFreelancerModalWithFun("Message", "Profile updated successfully.", this.props.ReloadLoginProfile);
                                    // this.props.ReloadLoginProfile();
                                }
                                resolve("Success");
                            }
                        }
                        else {
                            console.log("Error in profile update:- " + result.errorDetail);
                            this.context.ShowFreelancerModal("Error", result.message);
                            this.context.ShowFreelanceLoader(false, "");
                            resolve("success");
                        }
                    }).catch(error => {
                        console.log("Error in calling save user profile service.");
                        console.log(error);
                        resolve("fail");
                        this.context.ShowFreelancerModal("Error", "Error in saving profile data.");
                        this.context.ShowFreelanceLoader(false, "");
                    });
            }
        })
    }

    private closeCommentDialog(value: string) {
        this.setState({
            isCommentDialogVisible: false
        }, () => {

        });
    }

    /// get context type
    static contextType = FreelancerContext;
    public render() {
        const extensionFilters = [".pdf", ".doc", ".docx", ".ppt", "pptx"];
        // Used to add spacing between example checkboxes
        const stackTokens = { childrenGap: 10 };
        return (
            <>
                {this.state.isCommentDialogVisible &&
                    <ModalComment
                        comment={userAdminComments}
                        closeDialog={this.closeCommentDialog}
                        commentMode={CommentMode.ViewAll}
                    />
                }
                <fieldset>
                    <legend>
                        User Profile
                    </legend>
                    <div className={this.props.UserDetail?.status == 'Active' ? "userProfileStatusSection Blue50" : "userProfileStatusSection Gray50"}>
                        <span className='lblStatusHeader'>Profile status :-</span><span className='lblStatusValue'>{this.props.UserDetail?.status ? this.props.UserDetail?.status : "Not submitted"}</span>
                    </div>
                    <Stack horizontal tokens={{ childrenGap: 100 }} className="userProfileSectionDiv">
                        <Stack className='userProfileStack'>
                            <Stack.Item className='userProfileItem'>
                                <TextField label="First name"
                                    autoComplete="off"
                                    disabled={this.props.ViewType != ProfileView.User}
                                    required={true}
                                    placeholder="Enter your first name"
                                    onChange={this.OnFirstNameChange.bind(this)}
                                    value={this.state.currentUserDetail?.firstName}></TextField>
                            </Stack.Item>
                            <Stack.Item className='userProfileItem'>
                                <TextField label="Last name"
                                    onChange={this.OnLastNameChange.bind(this)}
                                    disabled={this.props.ViewType != ProfileView.User}
                                    required={true}
                                    autoComplete="off"
                                    placeholder="Enter your last name"
                                    value={this.state.currentUserDetail?.lastName}></TextField>
                            </Stack.Item>
                            <Stack.Item className='userProfileItem'>
                                <TextField label="Email"
                                    disabled={true} autoComplete="off"
                                    placeholder="Enter your email"
                                    defaultValue={this.state.currentUserDetail?.title}>
                                </TextField>
                            </Stack.Item>
                            <Stack.Item className='userProfileItem'>
                                <TextField label="Telephone"
                                    required={true}
                                    disabled={this.props.ViewType != ProfileView.User}
                                    autoComplete="off"
                                    placeholder="Enter your telephone"
                                    value={this.state.currentUserDetail?.mobileNo}
                                    onChange={this.OnTelephoneChange.bind(this)}
                                ></TextField>
                            </Stack.Item>
                            {this.props.ViewType == ProfileView.AdminUser &&
                                <Stack.Item className='userProfileItem'>
                                    <Label className='disablelbl'>Rating</Label>
                                    <Rating
                                        className='disableRating'
                                        title={"User Rating " + String(userRating)}
                                        allowZeroStars
                                        max={5}
                                        // defaultValue={userRating}
                                        rating={userRating}
                                        ariaLabel={"Rating"}
                                        readOnly
                                        // disabled={true}
                                        ariaLabelFormat="{0} of {1} stars"
                                    /><Label> </Label>
                                    <div className='disablelbl'>**Base of events participation</div>
                                </Stack.Item>
                            }

                            {
                                isAttachmentSectionVisible &&
                                <Stack.Item className='userProfileItem'>
                                    <Label>Attachment</Label>
                                    <AttachmentComponent
                                        apiEndpointSP={`/api/SPInterface`}
                                        attachment={this.state.cvAttachments ? this.state.cvAttachments[0] : null}
                                        readonly={this.props.ViewType == ProfileView.AdminUser ? true : false}
                                        deletePermissions="All"
                                        enableDropZone={true}
                                        attachmentClickBehaviour="Download"
                                        extensionFilters={extensionFilters}
                                        fileMode="SingleFile"
                                        onFilesAdded={this.onCVAttachmentUploadAdded}
                                        onFileDeleted={this.onCVAttachmentDeleted}
                                        maxFileNameWidth={"700px"}
                                    />
                                </Stack.Item>
                            }
                        </Stack>
                        <Stack className='userProfileStack userProfileChoiceFields'>
                            <Stack>
                                <Stack horizontal>
                                    {
                                        (
                                            this.props.ViewType == ProfileView.User
                                            &&
                                            this.props.UserDetail?.status == AppConstants.ProfileStatus.Active
                                        )
                                            ?
                                            <Stack horizontal={false}>
                                                <Label className='optionsLabel'>Confirmed Job Area</Label>
                                                <Stack tokens={stackTokens}>
                                                    {
                                                        this.state.adminChoices.map((job: any, jobIndex: number) => {
                                                            return <Stack.Item key={"asjobdasd" + jobIndex} className='userProfileItem jobSelection'>
                                                                <Label className='rightAlign'>{job.title}
                                                                    <AppTooltip
                                                                        toolTipContent={this.props.MasterData?.userJobArea.filter((name: any) => name.id == job.id)[0].tooltip}
                                                                    />
                                                                </Label>
                                                                <Checkbox disabled={!isJobOptionVisible} onChange={this.ChangeUserJob.bind(this, job.id)} label="" checked={job.Selected} />
                                                            </Stack.Item>
                                                        })
                                                    }
                                                </Stack>
                                            </Stack>
                                            :
                                            <Stack horizontal={false}>
                                                <Label className='optionsLabel'>I am interested in the following Job Area and/or have experience in:</Label>
                                                <Stack tokens={stackTokens}>
                                                    {
                                                        this.state.userChoices.map((job: any, jobIndex: number) => {
                                                            return <Stack.Item key={"asjobdasd" + jobIndex} className='userProfileItem jobSelection'>
                                                                <Label className='rightAlign'>{job.title}
                                                                    <AppTooltip
                                                                        toolTipContent={this.props.MasterData?.userJobArea.filter((name: any) => name.id == job.id)[0].tooltip}
                                                                    />
                                                                </Label>
                                                                <Checkbox disabled={!isJobOptionVisible} onChange={this.ChangeUserJob.bind(this, job.id)} label="" checked={job.Selected} />
                                                            </Stack.Item>
                                                        })
                                                    }
                                                </Stack>
                                            </Stack>
                                    }

                                    {this.props.ViewType == ProfileView.AdminUser
                                        &&
                                        <>
                                            <div className="vl"></div>
                                            <Stack className='adminJobConfirm' horizontal={false}>
                                                <Label className='optionsLabel'>Admin Confirmation:</Label>
                                                <Stack tokens={stackTokens}>
                                                    {
                                                        this.state.adminChoices.map((job: any, jobIndex: number) => {
                                                            return <Stack.Item key={"AdminChoice" + jobIndex} className='userProfileItem jobSelection'>
                                                                <Checkbox disabled={!isAdminChoicesVisible} onChange={this.ChangeAdminJob.bind(this, job.id)} label="" checked={job.Selected} />
                                                            </Stack.Item>
                                                        })
                                                    }
                                                </Stack>
                                            </Stack>
                                        </>
                                    }
                                </Stack>
                                {
                                    (
                                        (this.props.ViewType == ProfileView.AdminUser
                                            && this.props.UserDetail?.status != AppConstants.ProfileStatus.Submitted
                                        )
                                        ||
                                        (this.props.ViewType == ProfileView.User
                                            && this.props.UserDetail?.status == AppConstants.ProfileStatus.Active
                                        )
                                    )
                                    &&
                                    <Stack className='UserEventDownload'>
                                        <Stack.Item>
                                            <ActionButton className='BlueColor100' iconProps={eventDownloadIcon}
                                                onClick={this.downloadUserEvents.bind(this)}
                                                allowDisabledFocus
                                            >Download Registered Events
                                            </ActionButton>
                                        </Stack.Item>
                                    </Stack>
                                }
                                {this.props.ViewType == ProfileView.AdminUser
                                    &&
                                    <Stack>
                                        <Stack.Item>
                                            <ActionButton className='BlueColor100' iconProps={{ iconName: 'View' }}
                                                onClick={this.viewAdminComments.bind(this)}
                                                allowDisabledFocus
                                            >View Admin comments
                                            </ActionButton>
                                        </Stack.Item>
                                    </Stack>
                                }
                                {this.props.ViewType == ProfileView.User
                                    &&
                                    <Stack>
                                        <Stack.Item>
                                            <Toggle
                                                label={<div>Receive Email Notifications</div>}
                                                inlineLabel
                                                onText="On"
                                                offText="Off"
                                                defaultChecked={this.state.currentUserDetail?.notifications
                                                    ? this.state.currentUserDetail?.notifications
                                                    : false}
                                                onChange={this._onNotificationChange.bind(this)}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                }
                            </Stack>
                            {this.props.ViewType == ProfileView.AdminUser &&
                                <Stack className='profileStatusdll'>
                                    <Dropdown
                                        calloutProps={{ directionalHintFixed: true }}
                                        label='Profile Status'
                                        onChange={this.onStatusChange.bind(this)}
                                        placeholder="Select status"
                                        options={this.state.options}
                                        selectedKey={this.state.selectedStatus}
                                    />
                                </Stack>
                            }
                            {this.state.IsRejectReasonVisible &&
                                <Stack>
                                    <TextField
                                        disabled={this.props.ViewType == ProfileView.User}
                                        required={true}
                                        value={this.state.currentUserDetail?.reason}
                                        onChange={this.OnReasonChange.bind(this)}
                                        label='Reason' multiline></TextField>
                                </Stack>
                            }

                        </Stack>
                    </Stack>
                    {isSubmitVisible &&
                        <Stack horizontal tokens={{ childrenGap: 20 }} className="fullWidth profileActionBTN">
                            <ActionButton
                                className="straumannBlueButton"
                                onClick={this.onSaveUserProfile}
                            >Update Profile</ActionButton>
                        </Stack>
                    }
                </fieldset>
            </>
        );
    }

    private isAdminChoicesVisible() {
        isAdminChoicesVisible = true;
        if (
            (
                this.props.ViewType == ProfileView.AdminUser
                &&
                (
                    this.props.UserDetail?.status == AppConstants.ProfileStatus.Rejected
                )
            )
            ||
            (
                this.props.ViewType == ProfileView.User
            )
        ) {
            isAdminChoicesVisible = false;
        }
    }
    private isJobOptionVisible() {
        isJobOptionVisible = true;
        if (
            (
                this.props.ViewType == ProfileView.User
                &&
                (
                    this.props.UserDetail?.status == AppConstants.ProfileStatus.Active
                    || this.props.UserDetail?.status == AppConstants.ProfileStatus.InProgress
                )
            )
            ||
            (
                this.props.ViewType == ProfileView.AdminUser
            )
        ) {
            isJobOptionVisible = false;
        }
    }
    private isSubmitVisible() {
        isSubmitVisible = false;
        if (
            (
                this.props.ViewType == ProfileView.User
            )
            ||
            (
                this.props.ViewType == ProfileView.AdminUser
                &&
                (this.props.UserDetail?.status == AppConstants.ProfileStatus.Submitted
                    || this.props.UserDetail?.status == AppConstants.ProfileStatus.InProgress
                    || this.props.UserDetail?.status == AppConstants.ProfileStatus.Active
                )
            )
        ) {
            isSubmitVisible = true;
        }
    }
    private isAttachmentSectionVisible() {
        isAttachmentSectionVisible = false;
        if (
            (
                this.props.ViewType == ProfileView.User
                &&
                (this.props.UserDetail?.status == AppConstants.ProfileStatus.Rejected
                    || this.props.UserDetail?.status == AppConstants.ProfileStatus.Submitted
                    || this.props.UserDetail?.status == undefined
                    || this.props.UserDetail?.status == null
                )
            )
            ||
            (
                this.props.ViewType == ProfileView.AdminUser
                &&
                (this.props.UserDetail?.status == AppConstants.ProfileStatus.Submitted
                    || this.props.UserDetail?.status == AppConstants.ProfileStatus.InProgress
                )
            )
        ) {
            isAttachmentSectionVisible = true;
        }
    }
    private getUserRating() {
        try {
            this.context.ShowFreelanceLoader(true, "");
            userEventsForDownload = [];
            userAdminComments = [];
            var eventQuery: IEventQuery = {
                EventID: null,
                FreelancerEmail: this.state.currentUserDetail.title,
                FreelancerID: null,
                Status: AppConstants.EventStatus.Final,
                DateRange: {
                    StartDate: "",
                    EndDate: Utility.CreateCAMLDateFormat(new Date())
                },
            };
            if (Utility.IsLoginUserActive(this.context.ExpireSession)) {
                this.props.dataProvider.getEvent(eventQuery)
                    .then((result) => {
                        var totalRating = 0;
                        result.forEach((item: any) => {
                            var submissions = JSON.parse(item.submission);
                            var userSubmissions = submissions.filter((item: any) => {
                                return item.Email == this.state.currentUserDetail.title;
                            });
                            if (userSubmissions.length == 1) {
                                var userSubmission = userSubmissions[0];
                                if (userSubmission?.ST && userSubmission?.IsP == true) {
                                    var jobStartTime: Date = userSubmission?.ST
                                        ? new Date(item.startTime)
                                        : null;
                                    var jobEndTime: Date = userSubmission?.ST
                                        ? new Date(item.endTime)
                                        : null;
                                    if (jobStartTime) {
                                        jobStartTime.setHours(userSubmission.ST.H, userSubmission.ST.M);
                                    }
                                    if (jobEndTime) {
                                        jobEndTime.setHours(userSubmission.ET.H, userSubmission.ET.M);
                                    }
                                    totalRating += userSubmission.Rating ? userSubmission.Rating : 0;
                                    userEventsForDownload.push(
                                        {
                                            Title: item.title,
                                            Description: item.description ? item.description : "",
                                            Job: userSubmission?.Text,
                                            "Job Start Time": Utility.GetClientDateTimeFormat(jobStartTime),
                                            "Job End Time": Utility.GetClientDateTimeFormat(jobEndTime),
                                            Hours: userSubmission?.Hours ? userSubmission.Hours : "",
                                            Rating: userSubmission.Rating ? userSubmission.Rating : 0
                                        }
                                    );
                                }
                                if (userSubmission.Reviewer) {
                                    if (userSubmission.Reviewer.Comment) {
                                        userSubmission.Reviewer.Event = item.title;
                                        userSubmission.Reviewer.Date = Utility.GetClientDateFormat(new Date(item.startTime));
                                        userAdminComments.push(userSubmission.Reviewer);
                                    }
                                }
                            }
                        });
                        if (userEventsForDownload.length > 0) {
                            userRating = totalRating / userEventsForDownload.length;
                        }
                        userRating = Number(userRating.toFixed(2));
                        this.context.ShowFreelanceLoader(false, "");
                    }).catch((error) => {
                        console.log(error);
                        this.context.ShowFreelanceLoader(false, "");
                        this.context.ShowFreelancerModal("Error", "Error in getting user events.");
                    });
            }
        }
        catch (error) {
            console.log(error);
            this.context.ShowFreelanceLoader(false, "");
            this.context.ShowFreelancerModal("Error", "Error in getting user events for profile.");
        }
    }
    private downloadUserEvents() {
        /// userEventsForDownload alreadt filled with rating method
        if (userEventsForDownload.length > 0) {
            csvExporter.options.filename = "User " + this.state.currentUserDetail.title;
            setTimeout(function () {
                csvExporter.generateCsv(userEventsForDownload);
            }, 1000);
        }
        else {
            this.context.ShowFreelancerModal("Message", "No event available for download.");
        }
        this.context.ShowFreelanceLoader(false, "");
    }
    private viewAdminComments() {
        this.setState({
            isCommentDialogVisible: true
        });
    }

    private _onNotificationChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        var userState = this.state.currentUserDetail;
        userState.notifications = checked;
        this.setState({
            currentUserDetail: userState
        });
    }
    public onStatusChange(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) {
        var userState = this.state.currentUserDetail;
        userState.reason = "";
        var isReasonVisible = false;
        if (item.key == AppConstants.ProfileStatus.Rejected) {
            isReasonVisible = true;
        }

        this.setState({
            selectedStatus: item.key,
            IsRejectReasonVisible: isReasonVisible,
            currentUserDetail: userState
        });
    };

    protected OnReasonChange(event: any) {
        var userState = this.state.currentUserDetail;
        userState.reason = event.target.value;
        this.setState({
            currentUserDetail: userState,
        });
    }
    protected OnTelephoneChange(event: any) {
        var userState = this.state.currentUserDetail;
        userState.mobileNo = event.target.value;
        this.setState({
            currentUserDetail: userState,
        });
    }
    protected OnFirstNameChange(event: any) {
        var userState = this.state.currentUserDetail;
        userState.firstName = event.target.value;
        this.setState({
            currentUserDetail: userState,
            IsAccountUpdateRequired: true
        });
    }
    protected OnLastNameChange(event: any) {
        var userState = this.state.currentUserDetail;
        userState.lastName = event.target.value;
        this.setState({
            currentUserDetail: userState,
            IsAccountUpdateRequired: true
        });
    }
    protected ChangeUserJob(jobID: number, ev: React.FormEvent<HTMLElement>, isChecked: boolean) {
        var userChoices = [...this.state.userChoices];
        var selectedItems = [];
        selectedItems = userChoices.filter((item) => {
            return (item.id == jobID);
        });
        if (selectedItems.length > 0) {
            selectedItems[0].Selected = isChecked;
        }
        this.setState({
            userChoices: userChoices
        });
    }
    protected ChangeAdminJob(jobID: number, ev: React.FormEvent<HTMLElement>, isChecked: boolean) {
        var adminChoices = [...this.state.adminChoices];
        var selectedItems = [];
        selectedItems = adminChoices.filter((item) => {
            return (item.id == jobID);
        });
        if (selectedItems.length > 0) {
            selectedItems[0].Selected = isChecked;
        }
        this.setState({
            adminChoices: adminChoices
        });
    }
    public componentDidMount() {
        this.getUserRating();
    }
    public componentWillReceiveProps(newProps: any) {
    }

}