import * as React from 'react';
import '../Main.scss';
import {
    ActionButton, Checkbox, css, Stack, PrimaryButton, TextField, Text, getTheme, Link, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, IDetailsRowProps, DetailsRow, Spinner, IconButton, Dialog, DialogFooter, DefaultButton, ConstrainMode, Icon, Label, DialogType, SpinnerSize, Dropdown, IDropdownOption, IDropdownStyles, IChoiceGroupOption, ChoiceGroup
} from 'office-ui-fabric-react';

import {
    DatePicker,
    IDatePickerStrings,
    addMonths,
    addYears,
    IDatePickerStyles,
} from 'office-ui-fabric-react';
import { IDataProvider } from '../../../../dataprovider/IDataProvider';
import { IEventQuery, IFreelancerEvent, IUserEventSelection } from '../../../../Interfaces/IUserDetails';
import { EventMode, TimeMode } from '../../../Utility/FreelancerEnums';
import { FreelancerContext } from '../../Common/FreelancerContext';
import { Utility } from '../../../Utility/Utility';
import { EventApplicant } from './SubEvents/EventApplicant';
import { AppTooltip } from '../../Common/AppTooltip';
import { AppConstants } from '../../../Utility/Constants';
import { EventAttendance } from './SubEvents/EventAttendance';
import { update } from 'lodash';
import { ExportToCsv, Options } from 'export-to-csv';
import { Confirmation } from '../../Common/Confirmation';
const options: Options = {
    filename: "All events",
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    // showTitle: true,
    // title: 'My Awesome CSV',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};
const csvExporter = new ExportToCsv(options);
export interface IManageEventProps {
    calendarInfo: any;
    dataProvider: IDataProvider;
    masterData: any;
    closeIfModal?: any;
    loginUserDetail: any;
    isUserAdmin: boolean;
    eventInfo: any;
}

export interface IManageEventState {
    availableJobs: any;
    eventDate: any;
    eventTime: any;
    eventTitle: string;
    eventDesc: string;
    eventJobUsers: any;
    userSelectionForEvent: any;
    eventStatus: string;
    event: any;
    showConfirmation: boolean;
}

const statusOptions: IDropdownOption[] = [
    {
        key: "Draft",
        text: "Draft"
    },
    {
        key: "Final",
        text: "Final"
    }
];
const hoursOptions: IDropdownOption[] = [];
for (let i = 0; i < 24; i++) {
    hoursOptions.push({
        key: i,
        text: i.toString()
    });
}

const minOptions: IDropdownOption[] = [];
for (let i = 0; i < 60; i++) {
    minOptions.push({
        key: i,
        text: i.toString()
    });
}
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 200 },
    // dropdownItem: { width: 300 },
};
const datePickerStyles: Partial<IDatePickerStyles> = { root: { maxWidth: 300 } };

let userJobsOptions: any[] = [];
let isLoginUserFilledJob: boolean = false;
let isValidDateForEvent: boolean = true;
var eventSubmissions: any = [];
var eventRequiredJobs: any = [];
var eventJobAttendance: any = [];
export class ManageEvent extends React.Component<IManageEventProps, IManageEventState> {
    constructor(MyProps: any) {
        super(MyProps);
        this.initializeVariables();
        this.state = {
            event: null,
            eventStatus: "",
            userSelectionForEvent: null,
            eventJobUsers: [],
            availableJobs: eventRequiredJobs,
            eventDate: null,
            eventTime: null,
            eventTitle: "",
            eventDesc: "",
            showConfirmation: false,
        };
        // this.context.ShowFreelanceLoader(true, "");
        this.renderEvent = this.renderEvent.bind(this);
        this.downloadEventAttendance = this.downloadEventAttendance.bind(this);
        Utility.addBlockBackgroundClass();
        Utility.removeEmptyDivIfPresent();
    }
    private initializeVariables() {
        userJobsOptions = [];
        isLoginUserFilledJob = false;
        isValidDateForEvent = true;
        eventSubmissions = [];
        eventRequiredJobs = [];
        eventJobAttendance = [];
    }
    protected BindUserJobOptions(eventRequiredJobs: any, eventSubmissions: any, IsUserJobPresent: boolean) {
        userJobsOptions = [];
        if (this.props.masterData) {
            var userProfileJobs: any = [];
            if (this.props.loginUserDetail.approvedJobs) {
                userProfileJobs = JSON.parse(JSON.stringify(this.props.loginUserDetail.approvedJobs));
            }
            /// disable user options if not approved in user profile by admin
            this.props.masterData.userJobArea.forEach((job: any) => {
                var filteredProfileJob = userProfileJobs.filter((item: any) => {
                    return (item.lookupId == job.id);
                });
                userJobsOptions.push({
                    key: job.id,
                    text: job.title,
                    disabled: filteredProfileJob.length > 0 ? false : true,
                    JobType: 1
                });
            });
        }

        /// Check if login user already filled job option then radio button should be disabled
        if (IsUserJobPresent) {
            userJobsOptions.forEach(element => {
                element.disabled = true;
            });
        }
        /// If all jobs are filled then radio button should be disable
        /// Check for all jobs filled
        userJobsOptions.forEach(element => {
            var requiredJobs = eventRequiredJobs.filter((item: any) => {
                return element.key == item.id;
            });
            var userApplicationsForJob = eventSubmissions.filter((item: any) => {
                return element.key == item.ID;
            });
            if (requiredJobs.length > 0) {

                if (userApplicationsForJob.length >= requiredJobs[0].count) {
                    element.JobType = 2;
                }
                /// Applicants should be double of required jobs(main and subordinate) 
                if (userApplicationsForJob.length >= (requiredJobs[0].count * AppConstants.NoOfApplicantForEachJob)) {
                    element.disabled = true;
                    element.JobType = 0;
                }

            }
            else {
                element.disabled = true;
                element.JobType = 0;
            }
        });



    }
    protected OnEventTitleChange(event: any) {
        this.setState({
            eventTitle: event.target.value
        });
    }
    public onTimeChange(timeMode: TimeMode, event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) {

        var timeslot = this.state.eventTime;
        switch (timeMode) {
            case TimeMode.StartHours:
                timeslot.start.hour = item.key;
                break;
            case TimeMode.StartMins:
                timeslot.start.min = item.key;
                break;
            case TimeMode.EndHours:
                timeslot.end.hour = item.key;
                break;
            case TimeMode.EndMins:
                timeslot.end.min = item.key;
                break;
            default:
                break;
        }
        this.setState({
            eventTime: timeslot,
        });
    };
    protected downloadEventAttendance() {
        try {
            this.context.ShowFreelanceLoader(true, "");
            eventJobAttendance = eventSubmissions;
            var userEventsForDownload: any[] = [];
            var Title = this.state.eventTitle;
            var Description = this.state.eventDesc;
            var eventStartDate = new Date(this.state.eventDate);
            eventStartDate.setHours(this.state.eventTime.start.hour, this.state.eventTime.start.min);

            var eventEndDate = new Date(this.state.eventDate);
            eventEndDate.setHours(this.state.eventTime.end.hour, this.state.eventTime.end.min);
            eventJobAttendance.forEach((item: any) => {
                if (item?.IsP == true) {
                    var jobStartTime: Date = item?.ST
                        ? new Date(eventStartDate)
                        : null;
                    var jobEndTime: Date = item?.ST
                        ? new Date(eventEndDate)
                        : null;
                    if (jobStartTime) {
                        jobStartTime.setHours(item.ST.H, item.ST.M);
                    }
                    if (jobEndTime) {
                        jobEndTime.setHours(item.ET.H, item.ET.M);
                    }
                    userEventsForDownload.push(
                        {
                            Title: Title,
                            // "Start Time": Utility.GetClientDateTimeFormat(eventStartDate),
                            // "End Time": Utility.GetClientDateTimeFormat(eventEndDate),
                            // Description: Description,
                            User: item?.Email,
                            Job: item?.Text,
                            Status: item?.Type + " " + item?.Order,
                            "Job Start Time": Utility.GetClientDateTimeFormat(jobStartTime),
                            "Job End Time": Utility.GetClientDateTimeFormat(jobEndTime),
                            Hours: item?.Hours ? item.Hours : "",
                            Rating: item?.Rating ? item.Rating : ""
                        }
                    );
                }

            });
            if (userEventsForDownload.length > 0) {
                csvExporter.options.filename = "Event Attendances";
                setTimeout(function () {
                    csvExporter.generateCsv(userEventsForDownload);
                }, 1000);
            }
            else {
                this.context.ShowFreelancerModal("Message", "No event available for download.");
            }
            this.context.ShowFreelanceLoader(false, "");
        }
        catch (err) {
            console.log(err);
            this.context.ShowFreelancerModal("Error", "Error in export.");
            this.context.ShowFreelanceLoader(false, "");
        }
    }
    /// get context type
    static contextType = FreelancerContext;
    public render() {
        return (
            <div key={"eventManageSection"}>
                {this.state.eventDate != null
                    &&
                    <div className="eventManageSection">
                        {!isValidDateForEvent &&
                            <div className='lockedEvent'>
                                <span className='eventMSG'>{this.props.isUserAdmin ? "Time to edit event has closed." : "Time to register for event has closed."}</span>
                            </div>
                        }

                        <Stack>
                            <Stack horizontal className='topStack'>
                                <Stack.Item className='lblPart'>
                                    <Label>Title</Label>
                                </Stack.Item>
                                <Stack.Item className='ctrlPart'>
                                    <TextField
                                        disabled={!this.props.isUserAdmin || !isValidDateForEvent}
                                        value={this.state.eventTitle}
                                        autoComplete="off"
                                        placeholder="Enter event title"
                                        onChange={this.OnEventTitleChange.bind(this)}
                                    ></TextField>
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal className='topStack'>
                                <Stack.Item className='lblPart'>
                                    <Label>Date</Label>
                                </Stack.Item>
                                <Stack.Item className='ctrlPart'>
                                    <DatePicker
                                        disabled
                                        styles={datePickerStyles}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        // strings={strings}
                                        placeholder="Select a date..."
                                        ariaLabel="Select a date"
                                        // minDate={minDate}
                                        // maxDate={maxDate}
                                        value={this.state.eventDate}
                                    // allowTextInput
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal className='topStack'>
                                <Stack.Item className='lblPart'>
                                    <Label>Start Time</Label>
                                </Stack.Item>
                                <Stack.Item className='ctrlPart'>
                                    <div className='timeCtrl hoursCtrl'>
                                        <Dropdown
                                            disabled={!this.props.isUserAdmin || !isValidDateForEvent}
                                            calloutProps={{ directionalHintFixed: true }}
                                            onChange={this.onTimeChange.bind(this, TimeMode.StartHours)}
                                            placeholder="Select Hours"
                                            options={hoursOptions}
                                            styles={dropdownStyles}
                                            selectedKey={this.state.eventTime.start.hour}
                                        /></div>
                                    <div className='timeCtrl minsCtrl'>
                                        <Dropdown
                                            disabled={!this.props.isUserAdmin || !isValidDateForEvent}
                                            calloutProps={{ directionalHintFixed: true }}
                                            onChange={this.onTimeChange.bind(this, TimeMode.StartMins)}
                                            placeholder="Select Minutes"
                                            options={minOptions}
                                            styles={dropdownStyles}
                                            selectedKey={this.state.eventTime.start.min}
                                        />
                                    </div>
                                </Stack.Item>

                            </Stack>
                            <Stack horizontal className='topStack'>
                                <Stack.Item className='lblPart'>
                                    <Label>End Time</Label>
                                </Stack.Item>
                                <Stack.Item className='ctrlPart'>
                                    <div className='timeCtrl hoursCtrl'>
                                        <Dropdown
                                            disabled={!this.props.isUserAdmin || !isValidDateForEvent}
                                            calloutProps={{ directionalHintFixed: true }}
                                            onChange={this.onTimeChange.bind(this, TimeMode.EndHours)}
                                            placeholder="Select Hours"
                                            options={hoursOptions}
                                            styles={dropdownStyles}
                                            selectedKey={this.state.eventTime.end.hour}
                                        />
                                    </div>
                                    <div className='timeCtrl minsCtrl'>
                                        <Dropdown
                                            disabled={!this.props.isUserAdmin || !isValidDateForEvent}
                                            calloutProps={{ directionalHintFixed: true }}
                                            onChange={this.onTimeChange.bind(this, TimeMode.EndMins)}
                                            placeholder="Select Minutes"
                                            options={minOptions}
                                            styles={dropdownStyles}
                                            selectedKey={this.state.eventTime.end.min}
                                        />
                                    </div>
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal className='topStack'>
                                <Stack.Item className='lblPart'>
                                    <Label>Description</Label>
                                </Stack.Item>
                                <Stack.Item className='ctrlPart'>
                                    <TextField multiline
                                        disabled={!this.props.isUserAdmin || !isValidDateForEvent}
                                        value={this.state.eventDesc}
                                        onChange={this.OnDescChange.bind(this)}
                                        className="calDescription"
                                    ></TextField>
                                </Stack.Item>

                            </Stack>
                            {this.props.isUserAdmin &&
                                <Stack horizontal className='topStack'>
                                    <Stack.Item className='lblPart'>
                                        <Label>Status</Label>
                                    </Stack.Item>
                                    <Stack.Item className='ctrlPart'>
                                        <div className='statusCtrl'>
                                            <Dropdown
                                                disabled={!isValidDateForEvent || (this.state.event?.status == "Final" ? true : false)}
                                                onChange={this.onStatusChange.bind(this)}
                                                placeholder="Select Status"
                                                options={statusOptions}
                                                styles={dropdownStyles}
                                                selectedKey={this.state.eventStatus}
                                            />
                                        </div>
                                    </Stack.Item>
                                </Stack>
                            }
                            <Stack horizontal className='topStackJob'>
                                <Stack.Item className='lblPart'>
                                    <Label>Required job profile:</Label>
                                </Stack.Item>
                                <Stack.Item className='ctrlPart'>
                                    <Label>{
                                        this.props.isUserAdmin ? "Count" : ""
                                    }</Label>
                                </Stack.Item>
                            </Stack>

                            {this.props.isUserAdmin &&
                                this.state.availableJobs.map((job: any, jobIndex: number) => {
                                    return <Stack horizontal className='topStackJob' key={"ajs" + job.id}>
                                        <Stack.Item key={"aj" + job.id}>
                                            <Label key={"lblaj" + job.id}>{job.title}<AppTooltip
                                                toolTipContent={this.props.masterData.userJobArea.filter((name: any) => name.id == job.id)[0].tooltip}
                                            /></Label>

                                        </Stack.Item>
                                        <Stack.Item className='ctrlPart'>
                                            <TextField
                                                key={"lalReq" + job.id}
                                                type="number"
                                                max={10}
                                                min="0"
                                                autoComplete="off"
                                                value={job.count}
                                                onChange={this.OnCountChange.bind(this, job.id)}
                                                disabled={!isValidDateForEvent || (this.state.event?.status == "Final" ? true : false)}
                                            >
                                            </TextField>
                                        </Stack.Item>
                                    </Stack>
                                })
                            }

                            {!this.props.isUserAdmin &&
                                userJobsOptions.map((job: any, jobIndex: number) => {
                                    return <><Stack horizontal className='topStackJob' key={"ujs" + job.key}>
                                        <Stack.Item key={"uj" + job.key}>
                                            <Label>{job.text}<AppTooltip
                                                toolTipContent={this.props.masterData.userJobArea.filter((name: any) => name.id == job.key)[0].tooltip}
                                            /></Label>
                                        </Stack.Item>
                                        <Stack.Item className='ctrlPart'>
                                            {this.state.userSelectionForEvent.ID == job.key
                                                ?
                                                <input
                                                    checked
                                                    type="radio"
                                                    id={job.text}
                                                    name="jobGroup"
                                                    disabled={job.disabled}
                                                    onClick={this._onChange.bind(this, job.key, job.text)}
                                                />
                                                :
                                                <input
                                                    type="radio"
                                                    id={job.text}
                                                    name="jobGroup"
                                                    disabled={job.disabled}
                                                    onClick={this._onChange.bind(this, job.key, job.text)}
                                                />
                                            }

                                        </Stack.Item>
                                    </Stack>
                                        {
                                            this.state.userSelectionForEvent.ID != job.key
                                            &&
                                            job.disabled == false
                                            &&
                                            job.JobType == 2
                                            &&
                                            <Stack className='jobInfo'><div>
                                                <span>Note** </span>:- Only substitute job available.
                                            </div></Stack>
                                        }
                                        {
                                            this.state.userSelectionForEvent.ID == job.key
                                            &&
                                            job.disabled == true
                                            &&
                                            <Stack className='jobInfo'><div>
                                                <span>Note** </span>:- You have registered as {this.state.userSelectionForEvent.Type == "Main" ? "main" : "substitute"} resource.
                                            </div></Stack>
                                        }
                                    </>
                                })
                            }
                        </Stack>
                        {this.props.isUserAdmin
                            && eventRequiredJobs.length > 0
                            && eventSubmissions.length > 0
                            &&
                            <EventApplicant eventRequiredJobs={eventRequiredJobs}
                                eventSubmissions={eventSubmissions}
                                event={this.state.event}
                            />
                        }
                        {!isValidDateForEvent &&
                            this.props.isUserAdmin
                            &&
                            <EventAttendance
                                eventRequiredJobs={eventRequiredJobs}
                                eventSubmissions={eventSubmissions}
                                loginUser={{
                                    Email: this.props.loginUserDetail.title,
                                    Name: this.props.loginUserDetail.firstName + " " + this.props.loginUserDetail.lastName,
                                }}
                                updateAttendance={this.updateAttendance}
                                downloadEventAttendance={this.downloadEventAttendance}
                            />
                        }
                        {
                            (this.props.isUserAdmin
                                ||
                                (isValidDateForEvent && !this.props.isUserAdmin)
                            )
                            &&
                            <Stack horizontal tokens={{ childrenGap: 20 }} className="fullWidth profileActionBTN">
                                {
                                    (this.props.isUserAdmin && !isValidDateForEvent && eventSubmissions.length == 0)
                                        ?
                                        <div className='noSubmissionAvailable'>No user registered for this event.</div>
                                        :
                                        <ActionButton className="straumannBlueButton"
                                            //  iconProps={{ iconName: 'Save' }}
                                            onClick={this.onSaveEvent.bind(this)}>{isLoginUserFilledJob ? "Deregister from Event" : "Submit Request"}</ActionButton>
                                }
                                {
                                    (
                                        this.props.isUserAdmin
                                        && eventSubmissions.length == 0
                                        && this.props.eventInfo.eventID > 0
                                    )
                                    && <ActionButton className="straumannBlueButton"
                                        onClick={this.ShowConfirmation.bind(this)}>Delete Event</ActionButton>

                                }
                            </Stack>
                        }
                        {!this.props.isUserAdmin &&
                            <Stack className='instructionSection'>
                                <Stack.Item>
                                    <span className='insHeader'>**Instruction</span>
                                </Stack.Item >
                                <Stack.Item className="instructionIfo">
                                    <ul>
                                        <li>A freelancer can opt for single job only.</li>
                                        <li>Freelancer can de-register from event using Deregister button.</li>
                                        <li>If all job are fill then freelancer will not be able to apply for job.</li>
                                    </ul>

                                </Stack.Item>
                            </Stack>
                        }
                    </div >
                }
                {this.state.showConfirmation == true &&
                    <Confirmation header='Confirm delete'
                        buttonText="Yes, Please delete"
                        message='Are you sure to delete this event. '
                        confirmed={this.Confirmed.bind(this)}
                        hideConfirmedDialog={this.HideConfirmation.bind(this)}
                    />
                }
            </div>
        );
    }
    protected ShowConfirmation() {
        this.setState({
            showConfirmation: true
        });
    }
    protected Confirmed() {
        this.context.ShowFreelanceLoader(true, "");
        this.setState({
            showConfirmation: false
        }, () => {
            this.props.dataProvider.deleteEvent(Number(this.props.eventInfo.eventID))
                .then((eventResult) => {
                    if (!eventResult.hasError) {
                        if (eventResult.data == true) {
                            this.context.ShowFreelancerModalWithFun("Message", "Event deleted successfully.", this.props.closeIfModal());

                        }
                        else {
                            this.context.ShowFreelancerModal("Error", "Not able to delete event. Please contact admin");
                        }
                    }
                    else {
                        console.log("Error in deleting event.");
                        console.log(eventResult);
                        this.context.ShowFreelancerModal("Error", "Not able to delete event. Please contact admin");
                    }
                    this.context.ShowFreelanceLoader(false, "");
                })
                .catch((error) => {
                    console.log(error);
                    this.context.ShowFreelancerModal("Error", "Error in calling deleting event.");
                    this.context.ShowFreelanceLoader(false, "");
                });
        });

    }

    protected HideConfirmation() {
        this.setState({
            showConfirmation: false
        });
    }
    protected updateAttendance(eventAttendance: any) {
        eventJobAttendance = eventAttendance;
    }
    public onStatusChange(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) {
        this.setState({
            eventStatus: item.key.toString()
        });
    }

    protected _onChange(jobID: number, value: string, event: any): void {
        var userSelectedOption = this.state.userSelectionForEvent;
        userSelectedOption.ID = jobID;
        userSelectedOption.Text = value;
        this.setState({
            userSelectionForEvent: userSelectedOption
        });
    }
    protected OnDescChange(event: any) {
        this.setState({
            eventDesc: event.target.value,
        });
    }
    protected OnCountChange(jobID: any, event: any) {
        var availableJobs = [...this.state.availableJobs];
        var selectedItems = [];
        selectedItems = availableJobs.filter((item) => {
            return (item.id == jobID);
        });
        if (selectedItems.length > 0) {
            selectedItems[0].count = event.target.value;
        }
        this.setState({
            availableJobs: availableJobs
        });
    }
    public onSaveEvent() {
        var eventStartDate = new Date(this.state.eventDate);
        eventStartDate.setHours(this.state.eventTime.start.hour, this.state.eventTime.start.min);

        var eventEndDate = new Date(this.state.eventDate);
        eventEndDate.setHours(this.state.eventTime.end.hour, this.state.eventTime.end.min);

        if (this.props.isUserAdmin) {
            if (eventStartDate < eventEndDate) {
                var Difference_In_Time = eventEndDate.getTime() - eventStartDate.getTime();
                var Difference_In_Mins = Difference_In_Time / (1000 * 60);
                if (Difference_In_Mins < 30) {
                    this.context.ShowFreelancerModal("Message", "Please select minimum 30 minute event time.");
                    return false;
                }
            }
            else {
                this.context.ShowFreelancerModal("Message", "End date should be greater than start date");
                return false;
            }


            if (!this.state.eventTitle) {
                this.context.ShowFreelancerModal("Message", "Please give event title.");
                return false;
            }
            if (!this.state.eventStatus) {
                this.context.ShowFreelancerModal("Message", "Please select event status.");
                return false;
            }
            var zeroRequirementRows = this.state.availableJobs.filter((i: any) => {
                return i.count == 0;
            });
            if (zeroRequirementRows.length == this.state.availableJobs.length) {
                this.context.ShowFreelancerModal("Message", "Please give count(>0) for a job for event.");
                return false;
            }
        }
        if (!this.props.isUserAdmin && this.state.userSelectionForEvent.ID <= 0) {
            this.context.ShowFreelancerModal("Message", "Please select job for event.");
            return false;
        }
        this.context.ShowFreelanceLoader(true, "");
        try {
            var eventValues: IFreelancerEvent = {
                EventID: this.props.eventInfo.eventID == 0 ? null : Number(this.props.eventInfo.eventID),
                Title: this.state.eventTitle,
                Description: this.state.eventDesc,
                StartTime: eventStartDate.toUTCString(),
                EndTime: eventEndDate.toUTCString(),
                Status: this.state.eventStatus,
                Requirements: JSON.stringify(this.state.availableJobs),
                IsAdmin: this.props.isUserAdmin,
                Attendance: eventJobAttendance.length == 0 ? "" : JSON.stringify(eventJobAttendance),
                DeregisterUser: isLoginUserFilledJob ? this.props.loginUserDetail?.title : "",
                Selection: (isLoginUserFilledJob == false && this.state.userSelectionForEvent.ID > 0)
                    ? this.state.userSelectionForEvent
                    : null,
            };
            if (Utility.IsLoginUserActive(this.context.ExpireSession)) {
                this.props.dataProvider.submitEvent(eventValues)
                    .then((result) => {
                        console.log(result);
                        this.props.closeIfModal();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.context.ShowFreelancerModal("Error", "Error in saving event.");
                        this.context.ShowFreelanceLoader(false, "");
                    });
            }
        } catch (ex) {
            console.log(ex);
            this.context.ShowFreelanceLoader(false, "");
            this.context.ShowFreelancerModal("Error", "Error in saving event data.");
        }
    }

    public componentDidMount() {
        this.renderEvent();
    }

    private async renderEvent() {
        this.context.ShowFreelanceLoader(true, "");
        try {
            var event = null;
            if (this.props.eventInfo.eventID != 0) {
                /// get event
                var eventQuery: IEventQuery = {
                    EventID: Number(this.props.eventInfo.eventID),
                    FreelancerEmail: "",
                    FreelancerID: null,
                    Status: "",
                    DateRange: null,
                };
                var eventColl = await this.props.dataProvider.getEvent(eventQuery);
                if (eventColl.length == 1) {
                    event = eventColl[0];
                }
            }
            /// initialize variables
            var calEventStartDate;
            var calEventEndDate;
            if (event != null) {
                calEventStartDate = event.startTime;
                calEventEndDate = event.endTime;

            }
            else {
                calEventStartDate = this.props.calendarInfo.start ? this.props.calendarInfo.start : event.startTime;
                calEventEndDate = this.props.calendarInfo.end ? this.props.calendarInfo.end : event.endTime;
            }
            calEventStartDate = new Date(calEventStartDate);
            calEventEndDate = new Date(calEventEndDate);
            isValidDateForEvent = this.props.isUserAdmin ? Utility.IsValidDateForEventCreation(calEventStartDate) : Utility.IsValidDateForEventRegistration(calEventStartDate);
            if (calEventStartDate.getDay() != calEventEndDate.getDay()) {
                calEventEndDate.setMinutes(calEventEndDate.getMinutes() - 1);
            }
            var calTime = {
                start: {
                    hour: calEventStartDate.getHours(),
                    min: calEventStartDate.getMinutes()
                },
                end: {
                    hour: calEventEndDate.getHours(),
                    min: calEventEndDate.getMinutes(),
                }
            }

            var userSelectionForEvent: IUserEventSelection = {
                ID: 0,
                Text: "",
                FirstName: this.props.loginUserDetail?.firstName,
                LastName: this.props.loginUserDetail?.lastName,
                Email: this.props.loginUserDetail?.title,
                PID: this.props.loginUserDetail?.id,
                AID: this.props.loginUserDetail?.userAccountId,

            }

            /// get user submissions if event is presend with user entry
            eventSubmissions = event
                ? (event.submission
                    ? JSON.parse(event.submission)
                    : []
                )
                : [];
            if (eventSubmissions.length > 0) {
                var loginUserSubmission = eventSubmissions.filter((item: any) => {
                    return (item.Email.toLowerCase() == this.props.loginUserDetail.title.toLowerCase())
                });
                if (loginUserSubmission.length) {
                    userSelectionForEvent = loginUserSubmission[0];
                    isLoginUserFilledJob = true;
                }
            }
            eventRequiredJobs = event
                ? (event.requirements
                    ? JSON.parse(event.requirements)
                    : []
                )
                : [];
            this.BindUserJobOptions(eventRequiredJobs, eventSubmissions, userSelectionForEvent.ID > 0 ? true : false);

            if (eventRequiredJobs.length == 0) {
                eventRequiredJobs = [];
                if (this.props.masterData) {
                    this.props.masterData.userJobArea.forEach((element: any) => {
                        eventRequiredJobs.push({
                            id: element.id,
                            title: element.title,
                            count: 0,
                        });
                    });
                }
            }
            this.setState({
                event: event,
                eventStatus: event ? event.status : "",
                userSelectionForEvent: userSelectionForEvent,
                eventJobUsers: [],
                availableJobs: eventRequiredJobs,
                eventDate: new Date(calEventStartDate),
                eventTime: calTime,
                eventTitle: event ? event.title : "",
                eventDesc: event ? event.description : "",
            }, () => {
                this.context.ShowFreelanceLoader(false, "");
            });
        } catch (err) {
            console.log(err);
            this.context.ShowFreelanceLoader(false, "");
            this.context.ShowFreelancerModal("Message", "Error in initializing form.");
        }
    }
}