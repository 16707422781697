var AppConstants = {
    loaderMSG: "Work in process. Please wait....",
    NoOfApplicantForEachJob: 2,
    mL: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    mS: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],

    AccountType: {
        Custom: "Custom",
        Microsoft: "Microsoft",
        Google: "Google"
    },
    ProfileQueryType: {
        UserAccount: "UserAccount",
        UserProfile: "UserProfile",
        UserEmail: "UserEmail"
    },

    Event: {
        StartHours: 9,
        StartMin: 0,
        EndHours: 18,
        EndMin: 0,
    },
    EventCalendar: {
        slotMinTime: "09:00",
        slotMaxTime: "18:00"
    },
    LoginUser: {
        Admin: "Admin",
        Freelancer: "Freelancer",
    },
    CalEventType: {
        dayGridMonth: "dayGridMonth",
        timeGridWeek: "timeGridWeek",
        timeGridDay: "timeGridDay",
    },
    CalendarAction: {
        EventCreationDayFromToday: 1,
        EventRegistrationDayFromToday: 1,
        MobileWidth: 768
    },
    MailType: {
        Standard: "Standard",
        Reminder: "Reminder"
    },
    EventStatus: {
        Draft: "Draft",
        Final: "Final",
    },
    ProfileStatus: {
        Submitted: "Submitted",
        Active: "Active",
        Rejected: "Rejected / Inactive",
        InProgress: "In Progress",
        NoSelection: "No Selection"
    }
}

export { AppConstants };