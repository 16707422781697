import { DefaultButton, Dialog, DialogFooter, DialogType, Stack } from 'office-ui-fabric-react';
import * as React from 'react';
import './Common.scss';
export interface IModalProps {
    // hideDialog: boolean;
    header: string;
    message: string;
    closeDialog: any;
}

export interface IModalState {
    // hideDialog: boolean;
}

export class ModalComponent extends React.Component<IModalProps, IModalState> {
    constructor(MyProps: any) {
        super(MyProps);
        this.state = {
            // hideDialog: this.props.hideDialog
        };
        this.toggleHideDialog = this.toggleHideDialog.bind(this);
    }

    public toggleHideDialog() {
        // this.setState({
        //     hideDialog: true
        // });
        this.props.closeDialog();

    }

    public render() {
        return (
            <>
                {/* <div id="myModal" className="modalFreelancer">
                    <div className="modalFreelancer-content">
                        <Stack>
                            <Stack.Item className='modalHeader'>
                                <div>{this.props.header}</div>
                            </Stack.Item>
                           
                            <Stack.Item className='modalMessage'>
                                <div>{this.props.message}</div>
                            </Stack.Item>
                            <Stack.Item className='modalFooter'>
                                <DefaultButton onClick={this.toggleHideDialog} text="Ok" />
                            </Stack.Item>
                        </Stack>
                    </div>

                </div> */}
                <Dialog
                    hidden={false}
                    onDismiss={this.toggleHideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: this.props.header,
                        subText: this.props.message,
                    }}
                    modalProps={{
                        isBlocking: true,
                        // styles: { main: { maxWidth: 800 } },
                    }}
                >
                    <DialogFooter>
                        <DefaultButton onClick={this.toggleHideDialog} text="Ok" />
                    </DialogFooter>
                </Dialog>

            </>
        );
    }

    public componentDidMount() {

    }
    public componentWillReceiveProps(newProps: any) {
        // if (this.state.hideDialog != newProps.hideDialog) {
        //     this.setState({ hideDialog: newProps.hideDialog });
        // }
    }

}