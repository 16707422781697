import { guid } from '@fullcalendar/react';
import { ActionButton, Dropdown, Icon, IDropdownOption, Stack } from 'office-ui-fabric-react';
import { Rating, RatingSize } from '@fluentui/react';

import * as React from 'react';
import { useState, useEffect } from 'react';
import { CommentMode, TimeMode } from '../../../../Utility/FreelancerEnums';
import '../Calendar.scss?1';
import { IEventQuery } from '../../../../../Interfaces/IUserDetails';
import { Checkbox } from 'office-ui-fabric-react/lib-commonjs';
import { ModalComment } from '../../../Common/ModalComment';
import { Utility } from '../../../../Utility/Utility';

export interface IEventAttendanceProps {
    eventRequiredJobs: any,
    eventSubmissions: any,
    updateAttendance: any,
    downloadEventAttendance: any,
    loginUser: any
}

export interface IEventAttendanceState {
    eventJobSubmissions: any,
    isCommentDialogVisible: boolean
}
let commentForFreelancer: string = "";
let commentEmail: string = "";

const hoursOptions: IDropdownOption[] = [];
for (let i = 0; i < 24; i++) {
    hoursOptions.push({
        key: i,
        text: i.toString()
    });
}

const minOptions: IDropdownOption[] = [];
for (let i = 0; i < 60; i++) {
    minOptions.push({
        key: i,
        text: i.toString()
    });
}
export const EventAttendance: React.FunctionComponent<IEventAttendanceProps> = ({ ...props }) => {
    let [eventSubmissionsState, setEventSubmissions] = useState(props.eventSubmissions);
    let [isCommentDialogVisible, CommentDialogVisible] = useState(false);

    const onRatingChage = (email: string, e: React.FormEvent<HTMLElement>, rating?: number) => {

        var submissions = eventSubmissionsState;
        var filterEntry = submissions.filter((submission: any) => {
            return submission.Email == email;
        });
        if (filterEntry.length > 0) {
            if (!filterEntry[0]?.Rating) {
                filterEntry[0].Rating = 0;
            }
            filterEntry[0].Rating = rating;
        }
        setEventSubmissions([...eventSubmissionsState]);
        props.updateAttendance(eventSubmissionsState);
    }
    const onAvailabilityChange = (email: string, event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
        var submissions = eventSubmissionsState;
        var filterEntry = submissions.filter((submission: any) => {
            return submission.Email == email;
        });
        if (filterEntry.length > 0) {
            filterEntry[0].IsP = checked;
        }
        setEventSubmissions([...eventSubmissionsState]);
        props.updateAttendance(eventSubmissionsState);
    }
    const onTimeChange = (timeMode: TimeMode, email: string, event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {

        var submissions = eventSubmissionsState;
        var filterEntry = submissions.filter((submission: any) => {
            return submission.Email == email;
        });
        if (filterEntry.length > 0) {
            if (!filterEntry[0].ST) {
                filterEntry[0].ST = { H: 0, M: 0 };
            }
            if (!filterEntry[0].ET) {
                filterEntry[0].ET = { H: 0, M: 0 };
            }
            switch (timeMode) {
                case TimeMode.StartHours:
                    filterEntry[0].ST.H = item.key;
                    break;
                case TimeMode.StartMins:
                    filterEntry[0].ST.M = item.key;
                    break;
                case TimeMode.EndHours:
                    filterEntry[0].ET.H = item.key;
                    break;
                case TimeMode.EndMins:
                    filterEntry[0].ET.M = item.key;
                    break;
                default:
                    break;
            }
            var eventStartDate = new Date();
            eventStartDate.setHours(filterEntry[0].ST.H, filterEntry[0].ST.M);

            var eventEndDate = new Date();
            eventEndDate.setHours(filterEntry[0].ET.H, filterEntry[0].ET.M);
            var Difference_In_Time = eventEndDate.getTime() - eventStartDate.getTime();
            var Difference_In_Hours = Difference_In_Time / (1000 * 60 * 60);

            filterEntry[0].Hours = (Difference_In_Hours % 1 != 0) ? Difference_In_Hours.toFixed(2) : Difference_In_Hours;
        }
        setEventSubmissions([...eventSubmissionsState]);
        props.updateAttendance(eventSubmissionsState);

    };
    function closeDialog(comment: string) {
        Utility.removeEmptyDivIfPresent();
        if (comment == null) {
            CommentDialogVisible(false);
        } else {
            isCommentDialogVisible = false;
            var submissions = eventSubmissionsState;
            var filterEntry = submissions.filter((submission: any) => {
                return submission.Email == commentEmail;
            });
            if (filterEntry.length > 0) {
                filterEntry[0].Reviewer = props.loginUser;
                filterEntry[0].Reviewer.Comment = comment;
            }
            setEventSubmissions([...eventSubmissionsState]);
            commentEmail = "";
            props.updateAttendance(eventSubmissionsState);
            CommentDialogVisible(isCommentDialogVisible);
        }
    }
    function downloadEventAttendance() {
        props.downloadEventAttendance();
    }
    function openCommentDialog(email: string) {
        // alert(email);
        commentEmail = email;
        isCommentDialogVisible = true;
        // commentForFreelancer = comment ? comment : "";
        var submissions = eventSubmissionsState;
        var filterEntry = submissions.filter((submission: any) => {
            return submission.Email == commentEmail;
        });
        if (filterEntry.length > 0) {
            commentForFreelancer = filterEntry[0].Reviewer?.Comment ? filterEntry[0].Reviewer?.Comment : "";
        }
        else {
            commentForFreelancer = "";
        }
        CommentDialogVisible(isCommentDialogVisible);
    }
    useEffect(() => {

    });
    return (
        <>
            {eventSubmissionsState.length > 0 &&
                <>
                    {isCommentDialogVisible &&
                        <ModalComment
                            comment={commentForFreelancer}
                            // hideDialog={this.state.modalValues.hideDialog}
                            closeDialog={closeDialog}
                            commentMode={CommentMode.Edit}
                        />
                    }
                    <ActionButton className='BlueColor100' iconProps={{ iconName: 'Download' }}
                        onClick={() => downloadEventAttendance()}
                        allowDisabledFocus
                    >Download Event Attendance
                    </ActionButton>
                    <Stack className='eventAttendance' key={"eventAttendance" + eventSubmissionsState.length}>

                        <table key={"table" + eventSubmissionsState.length} className='table table-bordered eventUsers'>
                            <thead><tr className='userEvent'>
                                <th className='email'>Email</th>
                                <th className='Job'>Job</th>
                                <th className='Position'>Position</th>
                                <th className='Present'>Present?</th>
                                <th className='Start'>Start Time</th>
                                <th className='End'>End Time</th>
                                <th className='Hours'>Hours</th>
                                <th className='Rating'>Rating</th>
                            </tr></thead><tbody key={"tbody" + eventSubmissionsState.length}>
                                {props.eventRequiredJobs.map((job: any, jobIndex: number) => {
                                    return eventSubmissionsState.filter((eventSubmissions: any) => { return (eventSubmissions.Text == job.title) }).map((event: any, eventIndex: number) => {
                                        return <>
                                            <tr className='userEvent' key={"userStack" + eventIndex}>
                                                <td key={"email" + eventIndex} className='email' title={event?.FirstName + " " + event?.LastName}>{event.Email}
                                                    <Icon iconName="CommentAdd"
                                                        title='Add comment for freelancer'
                                                        className='CommentAddIcon'
                                                        onClick={() => openCommentDialog(event.Email)}
                                                    /></td>
                                                <td key={"Job" + eventIndex} className='Job'>{event.Text}</td>
                                                <td key={"Position" + eventIndex} className='Position'>{event.Type + " " + event.Order}</td>
                                                <td key={"Present" + eventIndex} className='Present'><Checkbox
                                                    checked={event.IsP}
                                                    className="IsPresentChk"
                                                    onChange={(ev, checked) => onAvailabilityChange(event.Email, ev, checked)}
                                                ></Checkbox>
                                                </td>
                                                <td key={"Start" + eventIndex} className='Start'>
                                                    <div className='timeCtrl hoursCtrl'>
                                                        <Dropdown
                                                            disabled={event.IsP == undefined ? true : !event.IsP}
                                                            selectedKey={event?.ST ? Number(event.ST.H) : null}
                                                            placeholder="Hours"
                                                            options={hoursOptions}
                                                            onChange={(e, item) => onTimeChange(TimeMode.StartHours, event.Email, e, item)}
                                                        /></div>
                                                    <div className='timeCtrl minsCtrl'>
                                                        <Dropdown
                                                            disabled={event.IsP == undefined ? true : !event.IsP}
                                                            selectedKey={event?.ST ? Number(event.ST.M) : null}
                                                            placeholder="Minutes"
                                                            options={minOptions}
                                                            onChange={(e, item) => onTimeChange(TimeMode.StartMins, event.Email, e, item)}
                                                        />
                                                    </div>
                                                </td>
                                                <td key={"End" + eventIndex} className='End'>
                                                    <div className='timeCtrl hoursCtrl'>
                                                        <Dropdown
                                                            disabled={event.IsP == undefined ? true : !event.IsP}
                                                            selectedKey={event?.ET ? Number(event.ET.H) : null}
                                                            placeholder="Hours"
                                                            options={hoursOptions}
                                                            onChange={(e, item) => onTimeChange(TimeMode.EndHours, event.Email, e, item)}
                                                        /></div>
                                                    <div className='timeCtrl minsCtrl'>
                                                        <Dropdown
                                                            disabled={event.IsP == undefined ? true : !event.IsP}
                                                            selectedKey={event?.ET ? Number(event.ET.M) : null}
                                                            placeholder="Minutes"
                                                            options={minOptions}
                                                            onChange={(e, item) => onTimeChange(TimeMode.EndMins, event.Email, e, item)}
                                                        />
                                                    </div>
                                                </td>
                                                <td key={"Hours" + eventIndex} className='Hours'>{event?.Hours}</td>
                                                <td key={"Rating" + eventIndex} className='Rating'>
                                                    <Rating
                                                        className='ratingClass'
                                                        disabled={event.IsP == undefined ? true : !event.IsP}
                                                        // defaultRating={event?.Rating ? Number(event?.Rating) : 0}
                                                        rating={event?.Rating ? Number(event?.Rating) : 0}
                                                        allowZeroStars
                                                        max={5}
                                                        // ariaLabel="Small stars with 0 stars allowed"
                                                        ariaLabelFormat="{0} of {1} stars"
                                                        // onChange={(e, rating?) => onRatingChage(e, rating)}
                                                        onChange={(e, rating?) => onRatingChage(event.Email, e, rating)}
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    })
                                })}
                            </tbody></table>
                    </Stack>
                </>
            }
        </>
    );

};