import { Redirect } from "react-router-dom";
import { AppConstants } from "./Constants";


var Utility = {
    IsLoginUserActive: (callLogoutfunction: any) => {
        let IsLoginUserActive = false;
        if (window.sessionStorage.authToken) {
            let authToken = JSON.parse(window.sessionStorage.authToken);
            let expirationDate = new Date(authToken.expirationUTC);
            // console.log(expirationDate);
            if (expirationDate < new Date()) {
                // this token is expired. Clean it up
                if (callLogoutfunction != null) {
                    callLogoutfunction();
                }
                else {
                    window.sessionStorage.removeItem("authToken");
                    window.sessionStorage.removeItem("freelanceUser");
                    alert("Your Session expired. Please sign-in again.");
                }
                IsLoginUserActive = false;
            }
            else {
                IsLoginUserActive = true;
            }
        }
        if (IsLoginUserActive == false) {
            Utility.redirectHome();
        }
        return IsLoginUserActive;
    },
    IsValidDateForEventCreation: (startDate: Date) => {
        var currDate = new Date();
        var noActionDate = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate(), 0);
        noActionDate.setDate(noActionDate.getDate() + AppConstants.CalendarAction.EventCreationDayFromToday);
        return startDate > noActionDate;
    },
    IsValidDateForEventRegistration: (startDate: Date) => {
        var currDate = new Date();
        var noActionDate = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate(), 0);
        noActionDate.setDate(noActionDate.getDate() + AppConstants.CalendarAction.EventRegistrationDayFromToday);
        return startDate > noActionDate;
    },
    DateForEventCreation: () => {
        var currDate = new Date();
        var noActionDate = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate(), 0);
        noActionDate.setDate(noActionDate.getDate() + AppConstants.CalendarAction.EventCreationDayFromToday);
        return noActionDate;
    },
    // IsValidDateForEvent: (startDate: Date) => {
    //     var currDate = new Date();
    //     var noActionDate = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate(), 0);
    //     noActionDate.setDate(noActionDate.getDate() + AppConstants.CalendarAction.ActionDayCount);
    //     return startDate > noActionDate;
    // },
    // CriteriaDateForEvent: () => {
    //     var currDate = new Date();
    //     var noActionDate = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate(), 0);
    //     noActionDate.setDate(noActionDate.getDate() + AppConstants.CalendarAction.ActionDayCount);
    //     return noActionDate;
    // },
    pad: (number: number) => {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    },
    GetClientDateTimeFormat: (dt: Date) => {
        return dt ? Utility.pad(dt.getDate()) +
            '.' + Utility.pad(dt.getMonth() + 1) +
            '.' + dt.getFullYear() +
            '  ' + Utility.pad(dt.getHours()) +
            '.' + Utility.pad(dt.getMinutes())
            : ""
            ;
    },
    GetClientDateFormat: (dt: Date) => {
        return dt ? Utility.pad(dt.getDate()) +
            '.' + Utility.pad(dt.getMonth() + 1) +
            '.' + dt.getFullYear()
            : ""
            ;
    },
    CreateCAMLDateTimeFormat: (dt: Date) => {
        return dt.getFullYear() +
            '-' + Utility.pad(dt.getMonth() + 1) +
            '-' + Utility.pad(dt.getDate()) +
            'T' + Utility.pad(dt.getHours()) +
            ':' + Utility.pad(dt.getMinutes()) +
            ':' + Utility.pad(dt.getSeconds()) +
            'Z';
    },
    CreateCAMLDateFormat: (dt: Date) => {
        return dt.getFullYear() +
            '-' + Utility.pad(dt.getMonth() + 1) +
            '-' + Utility.pad(dt.getDate()) +
            'T00:00:00Z';
    },
    addBlockBackgroundClass: () => {
        /// code to remove multiple scroll after closing modal on panel
        try {
            var obj = document.getElementsByTagName("body");
            if (!obj[0].classList.contains("blockBackground")) {
                obj[0].classList.add("blockBackground");
            }
        }
        catch (err) { }
    },
    removeBlockBackgroundClass: () => {
        /// code to remove multiple scroll after closing modal on panel
        try {
            var obj = document.getElementsByTagName("body");
            if (obj[0].classList.contains("blockBackground")) {
                obj[0].classList.remove("blockBackground");
            }
        }
        catch (err) { }
    },
    removeEmptyDivIfPresent: () => {
        /// code to remove multiple scroll after closing modal on panel
        try {
            var obj = document.getElementById("fluent-default-layer-host");
            if (!obj.innerHTML) {
                obj.remove();
            }
        }
        catch (err) { }
    },
    getTabDueToParameter: () => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        let userParam: string;
        let eventParam: string;
        params.has('user') ? userParam = params.get("user") : userParam = "";
        params.has('event') ? eventParam = params.get("event") : eventParam = "";
        var tabOrder = -1;
        if (userParam) {
            tabOrder = 2;
        }
        if (eventParam) {
            tabOrder = 0;
        }
        return tabOrder;
    },
    getDefaultEvent: () => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        let eventParam: string;
        params.has('event') ? eventParam = params.get("event") : eventParam = "";
        return eventParam;
    },
    getDefaultUser: () => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        let userParam: string;
        params.has('user') ? userParam = params.get("user") : userParam = "";
        return userParam;
    },
    redirectHome: () => {
        window.location.replace(window.location.origin)
    }
};
export { Utility };