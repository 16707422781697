import { IDataProvider } from "./IDataProvider";
import HttpService from "./HttpService";
import { IAuthenticatedUser, IUserAccount } from "../components/LoginComponent/LoginComponent";
import { Common } from "../components/LoginComponent/Common";
import { IFileItem } from "../Interfaces/IFileItem";

export default class DataProvider implements IDataProvider {

    public apiEndpointSP = `/api/SPInterface`;
    public userAccountEndpointSP = `/api/UserAccountSPInterface`;
    
    public async getAppValues(): Promise<any> {
        const response = await HttpService.get(`${this.apiEndpointSP}/GetAppValues`);
        return response;
    }
    public async getUserType(userEmail: string): Promise<any> {
        const response = await HttpService.get(`${this.apiEndpointSP}/GetUserType/${userEmail}`);
        return response;
    }
    public async getUserProfile(userProfileQuery: any): Promise<any> {
        const response = await HttpService.post(`${this.apiEndpointSP}/GetUserProfile`, userProfileQuery);
        return response;
    }
    public async getAllUserProfiles(userQuery: any): Promise<any> {
        var path = "";
        if (userQuery.userEmail) {
        }
        else {
            userQuery.userEmail = "Default";
        }
        const response = await HttpService.post(`${this.apiEndpointSP}/GetAllUserProfiles`, userQuery);
        return response;
    }
    public async getMasterData(): Promise<any> {
        const response = await HttpService.get(`${this.apiEndpointSP}/MasterData`);
        return response;
    }

    public async submitUserProfile(userProfile: any): Promise<any> {

        const response = await HttpService.post(`${this.apiEndpointSP}/SaveUserProfile`, userProfile);
        return response;
    }
    public async sendMail(mailObj: any): Promise<any> {

        const response = await HttpService.post(`${this.apiEndpointSP}/SendFreelancerMail`, mailObj);
        return response;
    }
    public async submitEvent(eventData: any): Promise<any> {

        const response = await HttpService.post(`${this.apiEndpointSP}/SubmitEvent`, eventData);
        return response;
    }
    public async deleteEvent(eventID: number): Promise<any> {

        const response = await HttpService.post(`${this.apiEndpointSP}/DeleteEvent`, eventID);
        return response;
    }
    public async getEvent(eventQuery: any): Promise<any> {

        const response = await HttpService.post(`${this.apiEndpointSP}/GetEvents`, eventQuery);
        return response;
    }
    public async uploadCVAsAttachment(profileId: number, files: Array<IFileItem | File>): Promise<any> {

        var formData = new FormData();
        formData.append(`id`, profileId.toString());
        let fileNeedToDelete: string[] = [];
        files.map((file, index) => {
            if (file instanceof File)
                formData.append(`file${index}`, file);
            else if (file.isDeleted)
                fileNeedToDelete.push(file.uniqueId);
        });
        formData.append(`filesToDelete`, JSON.stringify(fileNeedToDelete));

        let authToken = window.sessionStorage.authToken ? JSON.parse(window.sessionStorage.authToken) : null;
        let response = await fetch(`${this.apiEndpointSP}/UploadUserCV`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': authToken ? `Bearer ${authToken.token}` : ""
            }
        });

        if (response.ok)
            return response.json();
        else if (response.status == 401) {
            throw "Upload CV Error: Unauthorized. Please sign out and sign back in and re-try.";
        }
        else
            throw await response.json();
    }

    public ensureExternalUserAccount(user: IUserAccount, token: string, isUserToken: boolean): Promise<IAuthenticatedUser> {
        return HttpService.post(`${this.userAccountEndpointSP}/EnsureExternalUserAccounts`, { "Account": user, "Token": token, "IsUserToken": isUserToken });
    }
    public registerInternalUserAccount(user: IUserAccount, reCaptchaToken: string): Promise<boolean> {
        return HttpService.post(`${this.userAccountEndpointSP}/RegisterUserAccount`, { "Account": user, "BaseUrl": Common.getCurrentServerUrl(), "reCaptchaToken": reCaptchaToken });
    }
    public loginInternalUserAccount(user: IUserAccount, reCaptchaToken: string): Promise<IAuthenticatedUser> {
        return HttpService.post(`${this.userAccountEndpointSP}/CustomUserLogin`, { "Account": user, "reCaptchaToken": reCaptchaToken });
    }
    public getUserByActivateParam(activationGuid: string, a: boolean): Promise<boolean> {
        return HttpService.post(`${this.userAccountEndpointSP}/GetUserByActivateParam`, { "activateParameter": activationGuid, "activate": a });
    }
    public resetPWByActivateParam(activationGuid: string, pw: string): Promise<boolean> {
        return HttpService.post(`${this.userAccountEndpointSP}/ResetPWByActivateParam`, { "activateParameter": activationGuid, "password": pw });
    }
    public getNewActivationCode(email: string, baseUrl: string): Promise<string> {
        return HttpService.post(`${this.userAccountEndpointSP}/GetActivationCode`, { "email": email, "baseUrl": baseUrl });
    }
    public resendActivationCode(email: string, baseUrl: string): Promise<string> {
        return HttpService.post(`${this.userAccountEndpointSP}/ResendActivationCode`, { "email": email, "baseUrl": baseUrl });
    }
    

}