import { DefaultButton, Dialog, DialogFooter, DialogType, Stack, TextField } from '@fluentui/react';
import { Label } from 'office-ui-fabric-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CommentMode } from '../../Utility/FreelancerEnums';
import { Utility } from '../../Utility/Utility';
import './Common.scss';
export interface IModalCommentProps {
    comment: any;
    closeDialog: any;
    commentMode: CommentMode;
}


export const ModalComment: React.FunctionComponent<IModalCommentProps> = ({ ...props }) => {
    let [commentState, SaveComment] = useState(props.comment);
    let [errorMSGState, SetErrorMSG] = useState("");
    useEffect(() => {
        
    });
    function toggleHideDialog() {
        props.closeDialog(null);
    }
    function saveComment() {
        if (props.commentMode == CommentMode.Edit) {
            if (commentState.length > 0) {
                SetErrorMSG("");
                props.closeDialog(commentState);
            }
            else {
                SetErrorMSG("Please provide comment.");
            }
        }
        else {
            props.closeDialog(props.comment);
        }
    }
    const onCommentChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        commentState = newValue;
        SetErrorMSG("");
    }
    return (
        <>
            <Dialog
                hidden={false}
                onDismiss={toggleHideDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: props.comment ? "Update Comment" : "Add Comment",
                }}
                modalProps={{
                    isBlocking: true,
                    className: "modalDialogComment",
                    styles: { main: { width: "750px !important", maxWidth: "750px !important", minWidth: "750px !important" } },
                }}
            >
                <div className='dataContainerSection'>
                    {props.commentMode == CommentMode.Edit &&
                        <div className='commentSection'>
                            <TextField
                                className='commenttxt'
                                defaultValue={props.comment}
                                required={true}
                                onChange={(e, value?) => onCommentChange(e, value)}
                                label='Comment' multiline></TextField>
                            <Label className='lblError'>{errorMSGState}</Label>
                        </div>
                    }
                    {
                        props.commentMode == CommentMode.ViewAll &&
                        <div className='adminComments'>
                            {props.comment.length > 0
                                ?
                                <table key={"table" + props.comment.length} className='table table-bordered commentTBL'>
                                    <thead><tr className='adminComment'>
                                        <th className='index'>Sr.</th>
                                        <th className='email'>Reviewer</th>
                                        <th className='comment'>Comment</th>
                                    </tr></thead><tbody key={"tbody" + props.comment.length}>
                                        {props.comment.map((comment: any, commentIndex: number) => {
                                            return <tr className='adminComment' key={"comment" + commentIndex}>
                                                <td className='index'>{commentIndex + 1}</td>
                                                <td className='email' title={"Comment by " + comment.Name + " for event '" + comment.Event + "' held on " + comment.Date}>{comment.Email}</td>
                                                <td className='comment'>{comment.Comment}</td>
                                            </tr>
                                        })}
                                    </tbody> </table>
                                :
                                <div className='noCommentsAvailable'>No comments available</div>
                            }
                        </div>
                    }
                </div>
                <div className='foolter'>
                    <DefaultButton onClick={saveComment} text="Ok" />
                </div>
            </Dialog>

        </>
    );
};
