import * as React from 'react';
import { IDataProvider } from '../../../../dataprovider/IDataProvider';
import { IUserAccount } from '../../../LoginComponent/LoginComponent';
import {
    ActionButton, Checkbox, css, Stack, PrimaryButton, TextField, Text, getTheme, Link, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, IDetailsRowProps, DetailsRow, Spinner, IconButton, Dialog, DialogFooter, DefaultButton, ConstrainMode, Icon, Label, DialogType, SpinnerSize, IStackTokens, IIconProps, TooltipHost, Panel, PanelType
} from 'office-ui-fabric-react';
import { SearchBox } from '@fluentui/react';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from 'office-ui-fabric-react';
import './ProfileManagement.scss';
import { FormLoader } from '../../Common/Loader';
import { UserProfile } from '../UserProfile/UserProfile';
import { ProfileView } from '../../../Utility/FreelancerEnums';
import { FreelancerContext } from '../../Common/FreelancerContext';
import { AppConstants } from '../../../Utility/Constants';
import { Utility } from '../../../Utility/Utility';
import { Util } from 'reactstrap';
export interface IProfileManagementProps {
    dataProvider: IDataProvider;
    currentUser?: IUserAccount;
    MasterData: any;
}

export interface IDocument {
    key: string;
    name: string;
    value: string;
    iconName: string;
    fileType: string;
    modifiedBy: string;
    dateModified: string;
    dateModifiedValue: number;
    fileSize: string;
    fileSizeRaw: number;
}
export interface IProfileManagementState {
    options: IDropdownOption[],
    userEmail: string,
    selectedStatus: any,
    listData: any,
    allListData: any,
    // ShowLoader: boolean;
    columns: IColumn[];
    isCompactMode: boolean;
    // LoaderMessage: string;
    filterText: string;
    isPanelOpen: boolean;
    selectedUserDetail: any;
    isQueryApplied: boolean;
}

const stackTokens: IStackTokens = { childrenGap: 20 };
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 200 },
    // dropdownItem: { width: 300 },
};
const addProfileSearchIcon: IIconProps = {
    iconName: 'ProfileSearch',
}
const viewIcon: IIconProps = { iconName: 'View' };
let iconPropsSearch = {
    iconName: 'ProfileSearch',
    onClick: () => {
        //alert('clicked the custom button!');
    }


};
export class ProfileManagement extends React.Component<IProfileManagementProps, IProfileManagementState> {
    private _selection: Selection;
    private _allItems: IDocument[];
    private loremIndex = 0;
    constructor(MyProps: any) {
        super(MyProps);
        const columns: IColumn[] = [
            {
                key: 'column0',
                name: 'Email',
                fieldName: 'email',
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
                isRowHeader: true,
                isCollapsible: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'column1',
                name: 'First Name',
                fieldName: 'firstName',
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Last Name',
                fieldName: 'lastName',
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Status',
                fieldName: 'status',
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                isPadded: true,
            },
            {
                key: 'action',
                name: 'Action',
                ariaLabel: 'Action',
                minWidth: 50,
                maxWidth: 60,
                onRender: (item: any) => {
                    return (
                        <Icon iconName="View"
                            title='View and edit'
                            className='actionIcon'
                            onClick={() => this.openPanel(item)} />
                    )
                },
            }
        ];

        var availableOptions: IDropdownOption[] = [];
        availableOptions.push({
            key: AppConstants.ProfileStatus.NoSelection,
            text: AppConstants.ProfileStatus.NoSelection
        });
        if (this.props.MasterData) {
            this.props.MasterData.userStatusColl.forEach((element: string) => {
                availableOptions.push({
                    key: element,
                    text: element
                });
            });
        }
        this.state = {
            isQueryApplied: false,
            selectedUserDetail: null,
            isPanelOpen: false,
            options: availableOptions,
            userEmail: "",
            selectedStatus: AppConstants.ProfileStatus.Submitted,
            listData: [],
            allListData: [],
            columns: columns,
            isCompactMode: false,
            // ShowLoader: false,
            // LoaderMessage: "",
            filterText: ""
        };
        this.GetUserData = this.GetUserData.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.onSearchImageClick = this.onSearchImageClick.bind(this);
        this.onEmailSearch = this.onEmailSearch.bind(this);
        iconPropsSearch.onClick = this.onSearchImageClick;

    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, listData } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = this._copyAndSort(listData, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            listData: newItems,
        });
    }
    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    private onSearchImageClick() {
        this.GetUserData();
    }
    protected onEmailSearch(event: any) {
        if (event.key === "Enter") {
            this.GetUserData();
        }
    }
    protected OnEmailChange(event: any) {
        var email = event.target.value;
        this.setState({
            userEmail: email
        });
    }
    private _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        var allListData = this.state.allListData;
        if (text) {
            allListData = allListData.filter((i: any) => {
                if (i.firstName.toLowerCase().indexOf(text) > -1
                    || i.lastName.toLowerCase().indexOf(text) > -1
                    || i.email.toLowerCase().indexOf(text) > -1
                ) {
                    return true;
                }
                else {
                    return false;
                }
            });
        }
        this.setState({
            listData: allListData,
            filterText: text,
        });
    }

    /// get context type
    static contextType = FreelancerContext;
    public render() {
        return (
            <>
                {/* <FormLoader
                    DialogType={DialogType.normal}
                    ShowLoader={this.state.ShowLoader}
                    SpinnerLabel={this.state.LoaderMessage}
                    SpinnerSize={SpinnerSize.large}
                /> */}
                <Panel
                    isOpen={this.state.isPanelOpen}
                    onDismiss={this.reloadData}
                    type={PanelType.large}
                    isBlocking={true}
                    // customWidth={panelType === PanelType.custom || panelType === PanelType.customNear ? '888px' : undefined}
                    closeButtonAriaLabel="Close"
                // headerText="User Profile"
                >
                    {this.state.selectedUserDetail &&
                        <UserProfile
                            ReloadLoginProfile={this.GetUserData}
                            dataProvider={this.props.dataProvider}
                            ViewType={ProfileView.AdminUser}
                            UserDetail={this.state.selectedUserDetail}
                            MasterData={this.props.MasterData}
                            IsCustomAccount={false}
                            ClosePanel={this.reloadData}
                        />
                    }
                </Panel>
                <fieldset>
                    <Stack>
                        <Stack tokens={stackTokens} horizontal className='searchSection'>
                            <Stack.Item className='searchOptions'>
                                <TextField
                                    label='User Email'
                                    autoComplete="off"
                                    placeholder="Enter user email"
                                    // defaultValue=""
                                    className='userSearch'
                                    onChange={this.OnEmailChange.bind(this)}
                                    onKeyUp={this.onEmailSearch.bind(this)}
                                    width={200}
                                    value={this.state.userEmail}
                                    iconProps={iconPropsSearch}
                                ></TextField>
                            </Stack.Item>
                            <Stack.Item className='searchOptions'>
                                {/* <Label>Status</Label> */}
                                <Dropdown
                                    calloutProps={{ directionalHintFixed: true }}
                                    label='Status'
                                    onChange={this.onStatusChange.bind(this)}
                                    placeholder="Select status"
                                    options={this.state.options}
                                    styles={dropdownStyles}
                                    selectedKey={this.state.selectedStatus}

                                />
                            </Stack.Item>
                            <Stack.Item className='searchOptions'>
                                <ActionButton iconProps={addProfileSearchIcon}
                                    onClick={this.onSearchPeople.bind(this)}
                                    allowDisabledFocus
                                >Search Profile
                                </ActionButton>
                            </Stack.Item>
                        </Stack>
                        {this.state.isQueryApplied &&
                            <Stack className='filterSearchResult'>
                                <TextField
                                    label="Filter results by First name/ Last name/ Email:"
                                    onChange={this._onChangeText}
                                    value={this.state.filterText}
                                    type="Search"
                                />
                            </Stack>
                        }
                        {this.state.listData.length > 0
                            ?

                            <Stack>
                                <DetailsList
                                    items={this.state.listData}
                                    compact={this.state.isCompactMode}
                                    columns={this.state.columns}
                                    selectionMode={SelectionMode.none}
                                    getKey={this._getKey}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                // onItemInvoked={this._onItemInvoked}
                                />
                            </Stack>
                            :
                            <Stack>
                                <div className='noDataAvailable'>No data available.</div>
                            </Stack>
                        }
                    </Stack>
                </fieldset>
            </>
        );
    }

    private reloadData(event: any) {
        /// Dialog above panel default calling panel cancel functionality
        /// CHeck type to prevent wrong call from modal dialog
        var processPanel = false;
        if (event) {
            if (event.type.toLowerCase() == "click") {
                processPanel = true;
            }
        }
        else {
            processPanel = true;
        }

        if (processPanel) {
            /// redired to url without parameter
            var paramUser = Number(Utility.getDefaultUser());
            if (paramUser > 0) {
                this.context.ShowFreelanceLoader(true, "");
                Utility.redirectHome();
            }
            else {
                this.GetUserData();
                this.setState({
                    isPanelOpen: false
                }, () => {
                    Utility.removeBlockBackgroundClass();
                });
            }
        }
    }
    private openPanel(item: any) {
        this.context.ShowFreelanceLoader(true, "");
        var userProfileQuery = {
            Value: String(item.profileId),
            Type: AppConstants.ProfileQueryType.UserProfile
        };
        if (Utility.IsLoginUserActive(this.context.ExpireSession)) {
            this.props.dataProvider.getUserProfile(userProfileQuery)
                .then((result) => {
                    this.setState({
                        isPanelOpen: true,
                        selectedUserDetail: result.userDetail,
                    });
                }).catch((error) => {
                    this.context.ShowFreelancerModal("Error", "Error in getting user profiles.");
                    this.context.ShowFreelanceLoader(false, "");
                });
        }
    }

    private onSearchPeople() {
        this.GetUserData();
    }
    private _getKey(item: any, index?: number): string {
        return item.key;
    }
    private _onItemInvoked(item: any): void {
        alert(`Item invoked: ${item.name}`);
    }

    public onStatusChange(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) {
        this.setState({
            selectedStatus: item.key,
        }, () => {
            this.GetUserData();
        });
    }
    public GetUserData() {
        this.context.ShowFreelanceLoader(true, "");
        this.setState({
            filterText: ""
        }, () => {

        });
        if (this.props.currentUser?.email && this.props.currentUser?.id) {
            var userQuery = {
                userStatus: this.state.selectedStatus,
                userEmail: this.state.userEmail
            };
            if (Utility.IsLoginUserActive(this.context.ExpireSession)) {
                this.props.dataProvider.getAllUserProfiles(userQuery)
                    .then((values) => {
                        this.setState({
                            listData: values,
                            allListData: values,
                            isQueryApplied: values.length > 0
                        }, () => {
                            this.context.ShowFreelanceLoader(false, "");
                        });
                    }).catch((error) => {
                        console.log(error);

                        this.setState({
                            listData: [],
                            allListData: [],
                            isQueryApplied: false
                        }, () => {
                            this.context.ShowFreelanceLoader(false, "");
                            this.context.ShowFreelancerModal("Error", "Error in getting user profiles.");
                        });
                    });
            }
        }
    }
    public componentDidMount() {
        this.GetUserData();

        this.context.ShowFreelanceLoader(true, "");
        var defaultUserParam = Utility.getDefaultUser();
        if (defaultUserParam) {
            var defaultUserID = Number(defaultUserParam);
            if (defaultUserID != NaN) {
                if (defaultUserID > 0) {
                    var userProfileQuery = {
                        Value: String(defaultUserID),
                        Type: AppConstants.ProfileQueryType.UserProfile
                    };
                    if (Utility.IsLoginUserActive(this.context.ExpireSession)) {
                        this.props.dataProvider.getUserProfile(userProfileQuery)
                            .then((result) => {
                                if (result.userDetail) {
                                    this.setState({
                                        isPanelOpen: true,
                                        selectedUserDetail: result.userDetail,
                                    });
                                }
                                else {
                                    this.context.ShowFreelancerModalWithFun("Error", "No user profile present.", Utility.redirectHome);
                                }
                            })
                            .catch((error) => {
                                this.context.ShowFreelancerModalWithFun("Error", "Error in getting user profiles.", Utility.redirectHome);
                            });
                    }
                }
                else {
                    this.context.ShowFreelancerModalWithFun("Error", "Error in getting user profiles.", Utility.redirectHome);
                }
            }
            else {
                this.context.ShowFreelancerModalWithFun("Error", "Error in getting user profiles.", Utility.redirectHome);
            }
        }
    }

}