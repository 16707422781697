import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, Link, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, IDetailsRowProps, DetailsRow, Spinner, IconButton, Dialog, DialogFooter, DefaultButton, ConstrainMode, Icon, DialogType, SpinnerSize } from 'office-ui-fabric-react';
import { PageURL } from '../../App';
import './Welcome.scss';
import { IUserAccount, LoginComponent } from '../LoginComponent/LoginComponent';
import { Redirect } from 'react-router';
import DataProvider from '../../dataprovider/DataProvider';
import { UserDashBoard } from './User/UserDashBoard'
import moment from 'moment';
import { FreelancerContext } from './Common/FreelancerContext';
import { FormLoader } from './Common/Loader';
import { ModalComponent } from './Common/ModalComponent';
import { Utility } from '../Utility/Utility';
import { AppConstants } from '../Utility/Constants';

const theme = getTheme();

export interface IWelcomePageProps {
    location: Location;
    history: any;
    stepSizeClass: string;
    isLoggedIn: boolean;
    dataProvider: DataProvider;
    currentUser?: IUserAccount;
    sessionExpired: any;
}

export interface IWelcomePageState {
    showLoader: boolean;
    loaderMSG: string;
    isLoggedIn: boolean;
    modalValues: any;
}
export class WelcomePage extends React.Component<IWelcomePageProps, IWelcomePageState> {
    constructor(props: IWelcomePageProps) {
        super(props);
        this.state = {
            modalValues: { hideDialog: true, header: "", msg: "" },
            showLoader: false,
            loaderMSG: "",
            isLoggedIn: props.isLoggedIn,
        };
        this.ShowFreelancerLoader = this.ShowFreelancerLoader.bind(this);
        this.ShowFreelancerModal = this.ShowFreelancerModal.bind(this);
        this.ShowFreelancerModalWithFun = this.ShowFreelancerModalWithFun.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }
    public componentDidMount() {

    }

    public componentWillReceiveProps(newProps: IWelcomePageProps) {
        if (this.state.isLoggedIn != newProps.isLoggedIn) {
            this.setState({ isLoggedIn: newProps.isLoggedIn }, () => {
                // this.setLoggedInInformation();
            });
        }
    }

    public ShowFreelancerLoader(showLoader: boolean, msg: any) {

        this.setState({
            loaderMSG: msg.length > 0 ? msg : AppConstants.loaderMSG,
            showLoader: showLoader
        });
    }
    public ShowFreelancerModal(header: string, message: string) {
        this.setState({
            modalValues: { hideDialog: false, header: header, msg: message }
        });
    }
    public ShowFreelancerModalWithFun(header: string, message: string, func: any) {
        this.setState({
            modalValues: { hideDialog: false, header: header, msg: message, func: func }
        }, () => {
            // func();
        });
    }
    public closeDialog() {
        var func = this.state.modalValues.func;
        this.setState({
            modalValues: { hideDialog: true, header: "", msg: "" }
        }, () => {
            if (func) {
                func();
            }
        });
    }
    public render(): React.ReactElement<IWelcomePageProps> {
        return (
            <FreelancerContext.Provider value={{
                ShowFreelanceLoader: this.ShowFreelancerLoader,
                ShowFreelancerModal: this.ShowFreelancerModal,
                ShowFreelancerModalWithFun: this.ShowFreelancerModalWithFun,
                ExpireSession: this.props.sessionExpired
            }}>
                {!this.state.modalValues.hideDialog &&
                    <ModalComponent
                        header={this.state.modalValues.header}
                        message={this.state.modalValues.msg}
                        closeDialog={this.closeDialog}
                    />
                }
                {this.state.showLoader &&
                    <FormLoader
                        DialogType={DialogType.normal}
                        ShowLoader={this.state.showLoader}
                        SpinnerLabel={this.state.loaderMSG}
                        SpinnerSize={SpinnerSize.large}
                    />
                }
                <Stack className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>

                    <Stack verticalAlign="space-between" horizontalAlign="start" className={css("welcomeContainer", this.props.stepSizeClass, "animate", "popIn")} tokens={{ childrenGap: 5 }}>
                        {this.state.isLoggedIn
                            ? <>
                                {this.props.currentUser &&
                                    <UserDashBoard dataProvider={this.props.dataProvider} currentUser={this.props.currentUser} />
                                }
                            </>
                            :
                            <Stack horizontal className="fullWidth" horizontalAlign="center"><div style={{ fontWeight: 'bold' }}>Please sign in to be able to create a request</div></Stack>
                        }



                    </Stack>

                </Stack>
            </FreelancerContext.Provider>
        );
    }
}