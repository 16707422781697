import { guid } from '@fullcalendar/react';
import { DefaultButton, DirectionalHint, Icon, ITooltipHostStyles, ITooltipProps, TooltipDelay, TooltipHost } from 'office-ui-fabric-react';
import * as React from 'react';
export interface IAppTooltipProps {
    toolTipContent: string
}


const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export const AppTooltip: React.FunctionComponent<IAppTooltipProps> = ({ ...props }) => {
    // Use useId() to ensure that the ID is unique on the page.
    // (It's also okay to use a plain string and manually ensure uniqueness.)
    const { toolTipContent } = props;
    const tooltipId = guid();
    const tooltipProps: ITooltipProps = {
        onRenderContent: () => (
            <div style={{ margin: 10, padding: 0 }}>
                {toolTipContent}
            </div>
        ),
    };
    return (
        <TooltipHost
            tooltipProps={tooltipProps}
            delay={TooltipDelay.zero}
            id={tooltipId}
            directionalHint={DirectionalHint.topLeftEdge}
            styles={hostStyles}
        >
            {/* <DefaultButton aria-describedby={tooltipId} text="Hover over me" /> */}
            <Icon iconName="AlertSolid" className='iconAlertColor BlueColor100' aria-describedby={tooltipId} />
        </TooltipHost>
    );
};
