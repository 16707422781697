import React, { Component } from 'react';
import { Redirect, Route } from 'react-router';
import { Layout } from './components/Layout/Layout.tsx?1';
import { WelcomePage } from './components/Welcome/Welcome.tsx?1';
import { LoginComponent } from './components/LoginComponent/LoginComponent';
import { ConfirmPage } from './components/LoginComponent/ConfirmPage';
import { ResetPWPage } from './components/LoginComponent/ResetPWPage';
import { ExternalConsent } from './components/LoginComponent/ExternalConsent';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Common } from './components/LoginComponent/Common';
import './custom.scss'
import DataProvider from './dataprovider/DataProvider';
import { CookieList } from './components/LoginComponent/CookieList';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AppConstants } from './components/Utility/Constants';

const CLIENT_ID = "203645364480-27bhv5or3e92bf62escq19het0eturtr.apps.googleusercontent.com";
//const CLIENT_ID = "313235099716-c5ttur4f2v0o001gd3vngd55kmhirihm.apps.googleusercontent.com";

export const PageURL = {
    ConfirmationPage: "/confirm",
    ResetPWPage: "/resetPW",
    Consent: "/consent",
    CookieList: "/cookies"
}

function getMSALConfig() {
    let returnURI = Common.getCurrentServerUrl();
    return {
        auth: {
            clientId: "1f886d40-4c42-4b6d-aa39-b7996c42d73e",
            authority: "https://login.microsoftonline.com/common",
            redirectUri: `${returnURI}`
        },
        cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        }
    };
}

const msalInstance = new PublicClientApplication(getMSALConfig());


export default class App extends Component {
    refLoginComponent = null;

    constructor() {
        super();
        this.state = {
            authenticationData: {
                showGAuthentication: false,
                showMSAuthentication: false,
                showCustom: false,
                msUsersAllowed: []
            },
            isLoggedIn: false,
            currentUser: null,
            path: "/",
            redirectHome: false
        };

        this.refLoginComponent = React.createRef();
        this.setAppSettings = this.setAppSettings.bind(this);
        this.checkAndLogoutSession = this.checkAndLogoutSession.bind(this);
    }

    setAppSettings(data) {
        this.setState({
            authenticationData: {
                showGAuthentication: data.showGAuthentication,
                showMSAuthentication: data.showMSAuthentication,
                showCustom: data.showCustom,
                msUsersAllowed: data.msUsersAllowed
            }
        }, () => {
            this.checkAndLogoutSession();
        });
    }
    checkAndLogoutSession() {
        if (this.state.currentUser) {
            if (
                (this.state.currentUser.accountType == AppConstants.AccountType.Google
                    && this.state.authenticationData.showGAuthentication == false
                )
                ||
                (this.state.currentUser.accountType == AppConstants.AccountType.Custom
                    && this.state.authenticationData.showCustom == false
                )
                ||
                (this.state.currentUser.accountType == AppConstants.AccountType.Microsoft
                    && this.state.authenticationData.showMSAuthentication == false
                )
            ) {
                this.sessionExpired();
            }
        }
    }
    componentDidMount() {
        // in case the page is reloaded, check if there is a user in the session already
        if (window.sessionStorage.authToken) {
            let authToken = JSON.parse(window.sessionStorage.authToken);
            let expirationDate = new Date(authToken.expirationUTC);
            if (expirationDate < new Date()) {
                // this token is expired. Clean it up
                window.sessionStorage.removeItem("authToken");
                window.sessionStorage.removeItem("freelanceUser");
                alert("Your Session expired. Please sign-in again.");
            }
            if (window.sessionStorage.freelanceUser) {
                let currentUser = JSON.parse(window.sessionStorage.freelanceUser);
                console.log("..............................................");
                console.log(currentUser);
                this.setState({
                    isLoggedIn: true,
                    currentUser: currentUser
                });
            }
        }
    }

    componentWillReceiveProps(newProps) {
    }
    setAuthStatus = (loggedIn, user) => {
        return new Promise((resolve, reject) => {
            this.setState({ isLoggedIn: loggedIn, currentUser: user }, () => {
                console.log("%cLOG OUT HERE!!!!!", "color:yellow;font-size:16px");
                if (!user) {
                    sessionStorage.removeItem("authToken");
                    sessionStorage.removeItem("freelanceUser");
                    // redirect to home
                    window.location.href = `${window.location.protocol}//${window.location.host}`;
                }
                resolve();
            });
            console.log("%cCurrent User", "color:blue;font-size:16px", user);
        });
    }

    sessionExpired = () => {
        this.setState({
            isLoggedIn: false,
            currentUser: null
        });
        window.sessionStorage.removeItem("authToken");
        window.sessionStorage.removeItem("freelanceUser");
        alert("Your Session expired. Please sign-in again.");
        window.location.replace(window.location.origin);

    };

    static displayName = App.name;
    dataProvider = new DataProvider();

    
    render() {
        return (
            <Layout setAppSettings={this.setAppSettings}>
                {this.state.redirectHome && <Redirect to="/" />}
                <GoogleOAuthProvider clientId={CLIENT_ID}>
                    <MsalProvider instance={msalInstance}>
                        <Route exact path='/' render={(props) => {
                            if (this.state.path !== "/")
                                this.setState({ path: "/" });
                            return <WelcomePage
                                {...props}
                                dataProvider={this.dataProvider}
                                currentUser={this.state?.currentUser}
                                isLoggedIn={this.state.isLoggedIn}
                                sessionExpired={this.sessionExpired}
                            />;
                        }} />
                        <Route exact
                            path={PageURL.ConfirmationPage}
                            render={(props) => {
                                if (this.state.path !== PageURL.ConfirmationPage)
                                    this.setState({ path: PageURL.ConfirmationPage });
                                return <ConfirmPage {...props} dataProvider={this.dataProvider} />;
                            }}
                        />
                        <Route exact
                            path={PageURL.ResetPWPage}
                            render={(props) => {
                                if (this.state.path !== PageURL.ResetPWPage)
                                    this.setState({ path: PageURL.ResetPWPage });
                                return <ResetPWPage {...props} dataProvider={this.dataProvider} />;
                            }}
                        />
                        <Route exact
                            path={PageURL.Consent}
                            render={(props) => {
                                if (this.state.path !== PageURL.Consent)
                                    this.setState({ path: PageURL.Consent });
                                return <ExternalConsent setAuthStatus={this.setAuthStatus} loginComponent={this.refLoginComponent.current} {...props} />;
                            }
                            } />
                        <Route exact
                            path={PageURL.CookieList}
                            render={(props) => {
                                return <CookieList {...props} />;
                            }}
                        />
                        <div style={{ height: '100%' }}><LoginComponent authenticationData={this.state.authenticationData} key={this.state.path} ref={this.refLoginComponent} setAuthStatus={this.setAuthStatus} currentUser={this.state?.currentUser} /></div>
                    </MsalProvider>
                </GoogleOAuthProvider>
            </Layout>
        );
    }
}
