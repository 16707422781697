import * as React from 'react';
import { useRef, RefObject } from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import { DialogType, FontIcon, Panel, PanelType, SpinnerSize } from 'office-ui-fabric-react';
import { ManageEvent } from './ManageEvent';
import { IDataProvider } from '../../../../dataprovider/IDataProvider';
import { IEventQuery } from '../../../../Interfaces/IUserDetails';
import { EventMode } from '../../../Utility/FreelancerEnums';
import './Calendar.scss';
import { FormLoader } from '../../Common/Loader';
import { FreelancerContext } from '../../Common/FreelancerContext';
import { Utility } from '../../../Utility/Utility';
import { AppConstants } from '../../../Utility/Constants';
import { ExportToCsv, Options } from 'export-to-csv';
import { Constants } from '@azure/msal-common';
export interface IEventProps {
    dataProvider: IDataProvider;
    masterData: any;
    loginUserDetail: any;
    isUserAdmin: boolean;
}

export interface IEventState {
    currentEvents: any;
    isPanelOpen: boolean;
    panelHeader: string;
    calendarInfo: any;
    initialEvents: any;
    EventMode: EventMode;
}
var defaultEventID = 0;
var noActionDate = Utility.DateForEventCreation();
let eventQuery: IEventQuery = null;
let eventQueryStart: Date = null;
let eventQueryEnd: Date = null;
const options: Options = {
    filename: "All events",
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    // showTitle: true,
    // title: 'My Awesome CSV',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};
const csvExporter = new ExportToCsv(options);
export class EventCalendar extends React.Component<IEventProps, IEventState> {
    constructor(MyProps: any) {
        super(MyProps);
        eventQueryStart = null;
        eventQueryEnd = null;
        this.state = {
            currentEvents: [],
            isPanelOpen: false,
            panelHeader: "",
            calendarInfo: null,
            initialEvents: [],
            EventMode: null,
        };

        this.closePanel = this.closePanel.bind(this);
        // this.ShowLoader = this.ShowLoader.bind(this);
    }

    private closePanel(event: any) {
        /// Dialog above panel default calling panel cancel functionality
        /// CHeck type to prevent wrong call from modal dialog
        var processPanel = false;
        if (event) {
            if (event.type.toLowerCase() == "click") {
                processPanel = true;
            }
        }
        else {
            processPanel = true;
        }

        if (processPanel) {
            /// redired to url without parameter
            var paramUser = Number(Utility.getDefaultEvent());
            if (paramUser > 0) {
                this.context.ShowFreelanceLoader(true, "");
                Utility.redirectHome();
            }
            else {
                this.setState({
                    isPanelOpen: false,
                }, () => {
                    this.loadCalendarData(eventQuery);
                    Utility.removeBlockBackgroundClass();
                    Utility.removeEmptyDivIfPresent();
                });
            }
        }
    }


    /// get context type
    static contextType = FreelancerContext;

    calendarRef = React.createRef<FullCalendar>()
    public render() {
        return (
            <>
                <div className='demo-app'>
                    <div className='addNewEventContainer'>
                        <div className='addNewEvent'>
                            <FontIcon aria-label="Add Event" iconName="Add" className="AddEventIcon" />
                        </div>
                    </div>
                    {this.state.isPanelOpen &&
                        <Panel
                            key={"calendarPanel"}
                            isBlocking={true}
                            isOpen={this.state.isPanelOpen}
                            onDismiss={this.closePanel}
                            type={window.innerWidth <= AppConstants.CalendarAction.MobileWidth
                                ? PanelType.large
                                : (this.props.isUserAdmin ? PanelType.large : PanelType.medium)
                            }
                            closeButtonAriaLabel="Close"
                            headerText={this.state.panelHeader}
                        >
                            {this.state.isPanelOpen == true &&
                                <ManageEvent calendarInfo={this.state.calendarInfo}
                                    loginUserDetail={this.props.loginUserDetail}
                                    dataProvider={this.props.dataProvider}
                                    masterData={this.props.masterData}
                                    closeIfModal={this.closePanel}
                                    isUserAdmin={this.props.isUserAdmin}
                                    eventInfo={{
                                        eventID: this.state.EventMode == EventMode.Edit ? defaultEventID : 0
                                    }}
                                />
                            }
                        </Panel>
                    }
                    <div className='calendar-app-main'>
                        <FullCalendar
                            ref={this.calendarRef}

                            viewDidMount={(e: any) => this.viewDidMount(0, e)}
                            // viewWillUnmount={this.viewDidMount}
                            // height="auto"
                            // contentHeight={10}
                            // viewClassNames="viewCalendarClass"
                            dayCellClassNames="dayCellClassNames"
                            // allDayClassNames="allDayClassNames"
                            // eventClassNames="eventClassNames"
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            // businessHours={{
                            //     // days of week. an array of zero-based day of week integers (0=Sunday)
                            //     daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday

                            //     startTime: '10:00', // a start time (10am in this example)
                            //     endTime: '23:00', // an end time (6pm in this example)

                            // }}
                            slotMinTime={AppConstants.EventCalendar.slotMinTime}
                            slotMaxTime={AppConstants.EventCalendar.slotMaxTime}

                            // themeSystem='bootstrap'
                            customButtons={{
                                eventDownloadButton: {
                                    text: 'Download Attendance',
                                    click: this.DownloadEvents.bind(this),
                                },
                                prev: {
                                    text: 'Prev',
                                    click: (e: any) => this.viewDidMount(1, e)

                                },
                                next: {
                                    text: 'Next',
                                    click: (e: any) => this.viewDidMount(2, e)
                                },
                                today: {
                                    text: 'Today',
                                    click: (e: any) => this.viewDidMount(3, e)
                                }
                            }
                            }

                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                // right: 'eventDownloadButton dayGridMonth,timeGridDay'
                                right: this.props.isUserAdmin
                                    ? 'eventDownloadButton dayGridMonth,timeGridDay'
                                    : 'dayGridMonth,timeGridDay'
                            }}
                            slotLabelFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
                            eventTimeFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
                            initialView='dayGridMonth'
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={false}
                            weekends={true}
                            events={this.state.initialEvents}
                            allDaySlot={false}
                            // initialEvents={INITIAL_EVENTS}
                            select={this.handleSelect}
                            /// Event for mobile call only. on web geting called automatically
                            dateClick={
                                window.innerWidth <= AppConstants.CalendarAction.MobileWidth
                                    ? this.handleDateSelect
                                    : null
                            }

                            eventContent={renderEventContent} // custom render function
                            eventClick={this.handleEventClick}
                            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                            // eventChange={function (event: any) {

                            //     var v = "dsfs";
                            //     event.revert();
                            // }}
                            // you can update a remote database when these fire:
                            // eventAdd={function () { alert("Add"); }}
                            // eventChange={function () { alert("Change"); }}
                            // eventRemove={function () { alert("Remove") }}

                            // eventColor="yellow"
                            // eventTextColor="green"
                            // eventBackgroundColor="red"
                            // eventDidMount={this.dayCellDidMount.bind(this)}
                            dayCellDidMount={this.dayCellDidMount.bind(this)}

                        />
                    </div>
                </div>
            </>
        );

    }

    private viewDidMount(selectInfo: number, event: any) {
        // alert(1);
        try {

            var calendar: any = this.calendarRef.current;
            var startDate;
            var endDate;
            if (calendar) {
                switch (selectInfo) {
                    case 0:
                        break;
                    case 1:
                        calendar._calendarApi.prev();
                        break;
                    case 2:
                        calendar._calendarApi.next();
                        break;
                    case 3:
                        calendar._calendarApi.today();
                        break;
                    default:
                        break;
                }
                startDate = calendar._calendarApi.view.activeStart;
                endDate = calendar._calendarApi.view.activeEnd;
                if (calendar._calendarApi.view.type == AppConstants.CalEventType.timeGridDay) {
                    startDate.setDate(1);
                    endDate.setMonth(endDate.getMonth() + 1);
                    endDate.setDate(1);
                }
            }
            else {
                startDate = event.view.activeStart;
                endDate = event.view.activeEnd;
            }


            if (eventQueryStart == null
                || eventQueryEnd == null
                || new Date(startDate) < eventQueryStart
                || new Date(endDate) > eventQueryEnd
            ) {

                eventQuery = {
                    EventID: null,
                    FreelancerEmail: "",
                    FreelancerID: null,
                    Status: "",
                    DateRange: {
                        StartDate: Utility.CreateCAMLDateFormat(new Date(startDate)),
                        EndDate: Utility.CreateCAMLDateFormat(new Date(endDate))
                    },
                };
                if (!this.props.isUserAdmin) {
                    eventQuery.Status = AppConstants.EventStatus.Final
                }
                this.loadCalendarData(eventQuery);
                eventQueryStart = new Date(startDate);
                eventQueryEnd = new Date(endDate);
            }
        }
        catch (err) {
            console.log(err);
            this.context.ShowFreelancerModal("Error", "Error in loading events.");
        }
    }
    private DownloadEvents(event: any) {
        // console.log(this.calendarRef.current);
        var calendar: any = this.calendarRef.current;
        // console.log(calendar._calendarApi.view.currentStart);
        // console.log(calendar._calendarApi.view.currentEnd);
        var today = new Date();
        var qryStart = new Date();
        var qryEnd = new Date();
        if (calendar._calendarApi.view.type == AppConstants.CalEventType.timeGridDay) {
            qryStart = calendar._calendarApi.view.currentStart;
            qryEnd = calendar._calendarApi.view.currentStart;
            qryStart.setDate(1);
            qryEnd.setMonth(qryEnd.getMonth() + 1);
            qryEnd.setDate(1);
        }
        else {
            if (calendar._calendarApi.view.type == AppConstants.CalEventType.dayGridMonth) {
                qryStart = calendar._calendarApi.view.currentStart;
                qryEnd = calendar._calendarApi.view.currentEnd;
            }
            else {
                return false;
            }

        }
        if (qryStart > today) {
            this.context.ShowFreelanceLoader(false, "");
            this.context.ShowFreelancerModal("Message", "Please select older month to download report.");
            return false;
        }
        if (qryEnd > today) {
            qryEnd = today;
        }
        // var FullCalendar=new FullCalendar()
        this.context.ShowFreelanceLoader(true, "");
        var downloadQuery: IEventQuery = {
            EventID: null,
            FreelancerEmail: "",
            FreelancerID: null,
            Status: AppConstants.EventStatus.Final,
            DateRange: {
                StartDate: Utility.CreateCAMLDateFormat(new Date(qryStart)),
                EndDate: Utility.CreateCAMLDateFormat(new Date(qryEnd))
            },
        };
        if (Utility.IsLoginUserActive(this.context.ExpireSession)) {
            this.props.dataProvider.getEvent(downloadQuery)
                .then((result) => {
                    if (result.length > 0) {
                        var reportData: any[] = [];
                        result.forEach((calitem: any) => {
                            try {
                                var jobStartTime: Date = new Date(calitem.startTime);
                                var jobEndTime: Date = new Date(calitem.endTime);
                                var calTitle = calitem.title;
                                var attendance = calitem.submission ? JSON.parse(calitem.submission) : [];
                                if (attendance.length > 0) {
                                    attendance.sort(function (a: any, b: any) {
                                        return a.Email - b.Email;
                                    });
                                }
                                attendance.forEach((item: any) => {
                                    if (item?.IsP == true) {
                                        if (item.ST) {
                                            if (jobStartTime) {
                                                jobStartTime.setHours(item.ST.H, item.ST.M);
                                            }
                                            if (jobEndTime) {
                                                jobEndTime.setHours(item.ET.H, item.ET.M);
                                            }
                                            reportData.push({
                                                Title: calTitle,
                                                Job: item.Text,
                                                Name: item.FirstName + " " + item.LastName,
                                                Email: item.Email,
                                                "Start Time": Utility.GetClientDateTimeFormat(jobStartTime),
                                                "End Time": Utility.GetClientDateTimeFormat(jobEndTime),
                                                Hours: item.Hours,
                                                Rating: item.Rating ? item.Rating : 0
                                            });
                                        }
                                    }
                                });
                            }
                            catch (processError) {
                                console.log(processError);
                            }
                        });

                        if (reportData.length > 0) {
                            csvExporter.options.filename = AppConstants.mS[qryStart.getMonth()] + " " + qryStart.getFullYear();
                            this.context.ShowFreelanceLoader(false, "");
                            setTimeout(function () {
                                csvExporter.generateCsv(reportData);
                            }, 1000);
                        }
                        else {
                            this.context.ShowFreelancerModal("Message", "No records available for report.");
                            this.context.ShowFreelanceLoader(false, "");
                        }
                    }
                    else {
                        this.context.ShowFreelanceLoader(false, "");
                        this.context.ShowFreelancerModal("Message", "No events available for report.");
                    }
                }).catch((error) => {
                    console.log(error);
                    this.context.ShowFreelanceLoader(false, "");
                    this.context.ShowFreelancerModal("Error", "Error in getting user events.");
                });
        }

    }
    private dayCellDidMount(event: any) {
        var v = event;
        var vv = "";
        var cellDateTime = new Date(event.date);
        var cellDate = cellDateTime.getDate();
        var cellMonth = cellDateTime.getMonth();
        var cellYear = cellDateTime.getFullYear();
        event.el.className = event.el.className + " " + cellDate.toString() + cellMonth.toString() + cellYear.toString();


    }

    handleSelect = (selectInfo: any) => {
        // alert("web");
        if (!selectInfo.start) {
            selectInfo.start = selectInfo.date;
            selectInfo.end = selectInfo.date;
        }
        if (selectInfo.view.type != AppConstants.CalEventType.timeGridDay) {
            selectInfo.view.type = AppConstants.CalEventType.timeGridDay;
            selectInfo.view.calendar.changeView(AppConstants.CalEventType.timeGridDay, selectInfo.start);
            console.log("View change:- " + selectInfo.view.type);
        }
        else {
            let calendarApi = selectInfo.view.calendar
            calendarApi.unselect() // clear date selection
            if (this.props.isUserAdmin) {
                if (Utility.IsValidDateForEventCreation(selectInfo.start)) {
                    defaultEventID = 0;
                    this.setState({
                        isPanelOpen: true,
                        panelHeader: "Add event",
                        calendarInfo: selectInfo,
                        EventMode: EventMode.Add
                    });
                }
                else {
                    this.context.ShowFreelancerModal("Message", "Events can not be added before " + noActionDate.toDateString() + ".");
                }
            }
            else {
                this.context.ShowFreelancerModal("Message", "You don't have permision. Only admin can add events.")
            }
        }
    }
    handleDateSelect = (selectInfo: any) => {
        // alert("mobile");
        if (!selectInfo.start) {
            selectInfo.start = selectInfo.date;
            selectInfo.end = selectInfo.date;
        }
        if (selectInfo.view.type != AppConstants.CalEventType.timeGridDay) {
            selectInfo.view.type = AppConstants.CalEventType.timeGridDay;
            selectInfo.view.calendar.changeView(AppConstants.CalEventType.timeGridDay, selectInfo.start);
            console.log("View change:- " + selectInfo.view.type);
        }
        else {
            let calendarApi = selectInfo.view.calendar
            calendarApi.unselect() // clear date selection
            if (this.props.isUserAdmin) {
                if (Utility.IsValidDateForEventCreation(selectInfo.start)) {
                    defaultEventID = 0;
                    this.setState({
                        isPanelOpen: true,
                        panelHeader: "Add event",
                        calendarInfo: selectInfo,
                        EventMode: EventMode.Add
                    });
                }
                else {
                    this.context.ShowFreelancerModal("Message", "Events can not be added before " + noActionDate.toDateString() + ".");
                }
            }
            else {
                this.context.ShowFreelancerModal("Message", "You don't have permision. Only admin can add events.")
            }
        }
    }

    handleEventClick = (clickInfo: any) => {
        if (clickInfo.view.type != AppConstants.CalEventType.timeGridDay) {
            clickInfo.view.type = AppConstants.CalEventType.timeGridDay;
            clickInfo.view.calendar.changeView(AppConstants.CalEventType.timeGridDay, clickInfo.event.start);
            console.log("View change:- " + clickInfo.view.type);
        }
        else {
            defaultEventID = clickInfo?.event.id;
            this.setState({
                isPanelOpen: true,
                panelHeader: "Update event",
                calendarInfo: clickInfo,
                EventMode: EventMode.Edit
            });
        }
    }

    handleEvents = (events: any) => {
        this.setState({
            currentEvents: events
        })
    }

    private loadCalendarData(eventQuery: IEventQuery) {
        this.context.ShowFreelanceLoader(true, "");
        if (eventQuery == null) {
            eventQuery = {
                EventID: null,
                FreelancerEmail: "",
                FreelancerID: null,
                Status: "",
                DateRange: null,
            };
        }
        if (!this.props.isUserAdmin) {
            eventQuery.Status = AppConstants.EventStatus.Final
        }
        var initialEvents: any[] = [];// JSON.parse(JSON.stringify(this.state.initialEvents));
        if (Utility.IsLoginUserActive(this.context.ExpireSession)) {
            this.props.dataProvider.getEvent(eventQuery)
                .then((result) => {
                    // console.log(result);
                    result.forEach((element: any) => {
                        initialEvents.push({
                            editable: false,
                            id: element.eventID,
                            title: element.title,
                            start: element.startTime,
                            end: element.endTime,
                            startTime: element.startTime,
                            endTime: element.endTime,
                            description: element.description,
                            requirements: element.requirements,
                            submission: element.submission,
                            status: element.status,
                            isJobCompleted: element.isJobFilled,
                            classNames: element.isJobFilled ? "dayViewJobCompleted event" + element.eventID : "dayViewJobOpen event" + element.eventID,

                        }
                        );
                    });
                    // var v = INITIAL_EVENTS;
                    // initialEvents = INITIAL_EVENTS;
                    this.setState({
                        initialEvents: initialEvents,
                    }, () => {
                        this.context.ShowFreelanceLoader(false, "");
                    });
                }).catch((error) => {
                    console.log(error);
                    // alert("error");
                    this.context.ShowFreelanceLoader(false, "");
                    this.context.ShowFreelancerModal("Error", "Error in getting calendar events.");
                });
        }
    }
    public componentDidMount() {
        // this.loadCalendarData(eventQuery);
        // if (this.props.isUserAdmin) {
        var defaultEventParam = Utility.getDefaultEvent();
        if (defaultEventParam) {
            defaultEventID = Number(defaultEventParam);
            if (defaultEventID != NaN) {
                if (defaultEventID > 0) {
                    this.setState({
                        isPanelOpen: true,
                        panelHeader: "Update event",
                        EventMode: EventMode.Edit
                    });
                }
                else {
                    this.context.ShowFreelancerModalWithFun("Error", "No event available.", Utility.redirectHome);
                }
            } else {
                this.context.ShowFreelancerModalWithFun("Error", "No event available.", Utility.redirectHome);
            }
        }
        // }
    }

}

/// render content inside cell
function renderEventContent(eventInfo: any, target: any) {
    try {
        if (eventInfo.view.type != AppConstants.CalEventType.timeGridDay) {
            var cellDateTime = new Date(eventInfo.event.start);
            var cellDate = cellDateTime.getDate();
            var cellMonth = cellDateTime.getMonth();
            var cellYear = cellDateTime.getFullYear();
            // var aValue = cellYear + "-" + (cellMonth + 1) + "-" + cellDate;
            var obj = document.getElementsByClassName("event" + eventInfo.event.id);
            var dayCell;
            if (obj.length) {
                dayCell = obj[0].closest("td.dayCellClassNames");
            }
            else {
                var x = document.getElementsByClassName(cellDate.toString() + cellMonth.toString() + cellYear.toString());
                if (x.length > 0) {
                    dayCell = x[0];
                }
            }
            // var x = obj[0].closest("td.dayCellClassNames");
            // var x = document.querySelectorAll("[aria-labelledby='" + aValue + "']");
            // var x = document.getElementsByClassName(cellDate.toString() + cellMonth.toString() + cellYear.toString());
            if (dayCell) {
                /// if close event found
                if (eventInfo.event.extendedProps.isJobCompleted) {
                    if (!dayCell.classList.contains("eventJobOpened")) {
                        dayCell.classList.add("eventJobClosed");
                    }
                }
                /// if open event found
                if (!eventInfo.event.extendedProps.isJobCompleted) {
                    dayCell.classList.remove("eventJobClosed");
                    dayCell.classList.add("eventJobOpened");
                }
            }
        }
    }
    catch (err) {
        console.log("Error in rendering calendar.");
        console.log(err);
    }
    return (
        <>
            {/* <b>{eventInfo.timeText}</b> */}
            <i>{eventInfo.event.title}</i>
        </>
    )
}

