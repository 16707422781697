import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, SpinnerType, Stack, TextField } from '@fluentui/react';
import { xor } from 'lodash';
import { Label, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import DataProvider from '../../../../../dataprovider/DataProvider';
import { CommentMode } from '../../../../Utility/FreelancerEnums';
import '../Calendar.scss';
import { RenderApplicant } from './RenderApplicant';
import { Utility } from '../../../../Utility/Utility';
import { FormLoader } from '../../../Common/Loader';
import { useContext } from 'react';
import { FreelancerContext } from '../../../Common/FreelancerContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AppConstants } from '../../../../Utility/Constants';
export interface IEventMailProps {
    event: any,
    eventRequiredJobs: any,
    eventSubmissions: any,
}
let selectedUser: any = [];
let mailBody = "";
let mailSubject = "";


export const EventMail: React.FunctionComponent<IEventMailProps> = ({ ...props }) => {
    var dataProvider = new DataProvider();
    let eventState = props.event;
    let [isDialogHiddenState, SetDialogHidden] = useState(true);
    let [MailError, SetMailError] = useState("");

    const richTextInput = useRef(null);
    const topContext = useContext(FreelancerContext);
    const modules: any = {
        toolbar: [
            [{
                'header': [1, 2, 3, false]
            }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{
                'color': []
            }, {
                'background': []
            }],
            [{
                'list': 'ordered'
            }, {
                'list': 'bullet'
            }, {
                'indent': '-1'
            }, {
                'indent': '+1'
            }],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };
    const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video', 'background', 'color'];
    function onDismiss() {
        SetDialogHidden(true);
        Utility.removeEmptyDivIfPresent();
    }
    function sendMail() {
        var filterEntry = selectedUser.filter((selection: any) => {
            return selection.Checked == true;
        });
        if (filterEntry.length > 0
            && String(mailBody).trim().length > 0
            && String(mailSubject).trim().length > 0
        ) {

            var richTextField = richTextInput.current;
            var mailParameter = {
                emailProps: {
                    stage: "EventApplicantMail",
                    dataObject: {
                        mailTo: filterEntry.map((x: any) => x.Email).join(','),
                        mailSubject: mailSubject,
                        mailBody: mailBody.replaceAll("<p>", "<p style='margin:0px; padding:0px'>")
                    }
                },
                MailType: AppConstants.MailType.Standard,
                ID: eventState.eventID
            };
            topContext.ShowFreelanceLoader(true, "Mail sending is in progress...");
            // SetShowLoader(true);
            if (Utility.IsLoginUserActive(topContext.ExpireSession)) {
                dataProvider.sendMail(mailParameter)
                    .then((result: any) => {
                        // console.log(result);
                        if (result.hasError) {
                            ///  mail failed
                            SetMailError(result.message);
                        }
                        else {
                            /// mail send
                            SetMailError("");
                            SetDialogHidden(true);
                            topContext.ShowFreelancerModal("Message", "Mail send successfully.")
                        }
                        topContext.ShowFreelanceLoader(false, "");
                    }).catch((err: any) => {
                        console.log(err);
                        SetMailError("Error occur in sending mail.");
                        topContext.ShowFreelanceLoader(false, "");
                    });
            }

        } else {
            SetMailError("Please select users and provide mail subject/body to send mail.");
        }
        Utility.removeEmptyDivIfPresent();
    }
    function openMailDialog() {
        SetDialogHidden(false);
    }
    function returnSelection(selectedUserColl: any) {
        // alert(JSON.stringify(selectedUserColl));
        selectedUser = selectedUserColl;
        SetMailError("");
    }
    const onMailChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        mailBody = newValue;
        SetMailError("");
    }
    const onSubjectChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        mailSubject = newValue;
        SetMailError("");
    }
    function handleMailBodyChange(value: string) {
        mailBody = value;
        SetMailError("");
    }
    useEffect(() => {
        var eventSubmissions = eventState.submission
            ? JSON.parse(eventState.submission)
            : [];
        var uniqueUserColl: any = [];
        eventSubmissions.forEach((submission: any) => {
            var coll = uniqueUserColl.filter((item: any) => {
                return item.key == submission.Email
            });
            if (coll.length == 0) {
                uniqueUserColl.push({
                    key: submission.Email,
                    text: submission.FirstName + " " + submission.LastName
                });
            }
        });

    }, [JSON.stringify(props.event)]);
    return (
        <>
            <Icon iconName="NewMail"
                title='Send mail'
                className='NewEventMailIcon'
                onClick={() => openMailDialog()}
            />
            <Dialog
                hidden={isDialogHiddenState}
                onDismiss={onDismiss}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Send Mail",
                }}
                modalProps={{
                    isBlocking: true,
                    className: "modalDialogMail",
                    styles: { main: { width: "600px !important", maxWidth: "600px !important", minWidth: "600px !important" } },
                }}
            >
                <div className='dataContainerSec'>
                    <Stack className='applicantMailSec'>
                        <Stack.Item>
                            <RenderApplicant
                                eventRequiredJobs={props.eventRequiredJobs}
                                eventSubmissions={props.eventSubmissions}
                                returnSelection={returnSelection}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                autoComplete="off"
                                className="mailSubject"
                                required={true}
                                label='Mail Subject'
                                onChange={(e, value?) => onSubjectChange(e, value)}
                            ></TextField>
                        </Stack.Item>
                        <Stack.Item>
                            <ReactQuill
                                className='mailBodytxt'
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                // value={this.state.item.Description}
                                onChange={handleMailBodyChange}
                                ref={richTextInput}
                            ></ReactQuill>
                            {MailError.length > 0 &&
                                <Label className='lblMailError'>{MailError}</Label>
                            }
                        </Stack.Item>
                    </Stack>
                </div>
                <div className='foolter'>
                    <DefaultButton onClick={sendMail} text="Send Mail" />
                </div>
            </Dialog>
        </>
    );
};
