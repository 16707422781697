export enum ProfileView {
    User,
    Admin,
    AdminUser
}
export enum CommentMode {
    Edit,
    ViewAll
}

export enum EventMode {
    Add,
    Edit,
    Blank
}
export enum TimeMode {
    StartHours,
    StartMins,
    EndHours,
    EndMins
}

export let FreelanceConstants = {
    mediaQry: "480px"

}