import * as React from 'react';
import './Layout.scss';
import { Link } from 'react-router-dom';
import { Stack } from 'office-ui-fabric-react';
import DataProvider from '../../dataprovider/DataProvider';
import { values } from 'lodash';

const logo = require("../../images/str-group-white.svg")
const logo1 = require("../../images/Pool_logo_RGB.png")
const logo2 = require("../../images/Pool_logo_RGB_Black.png")
const logo3 = require("../../images/Pool_logo_RGB_White.png")

export interface ILayoutProps {
    setAppSettings: any;
}
export interface ILayoutState {
    ShowBanner: any;
    BannerData: any;
}
export class Layout extends React.Component<ILayoutProps, ILayoutState> {
    static displayName = Layout.name;
    private year = new Date().getFullYear();

    constructor(props: any) {
        super(props);
        this.state = {
            ShowBanner: false,
            BannerData: ""
        };
    }

    render() {
        return (
            <Stack className="fullWidth">
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="header">
                    <div className="appName"><a onClick={() => { window.location.href = window.location.origin; }}><img className="freelancerLogo" src={logo3} alt="Freelancer Logo"></img></a></div>
                    <div className="companyLogoContainer"><img className="companyLogo" src={logo} alt="Straumann Logo"></img></div>
                </Stack>
                {this.state.ShowBanner &&
                    <Stack className='redirectMessage'>
                        <div dangerouslySetInnerHTML={{ __html: this.state.BannerData }}>
                        </div>
                    </Stack>
                }
                <div className="mainContent">
                    <div className="container">{this.props.children}</div>
                </div>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="footer">
                    <div>© {this.year} Institut Straumann AG</div>
                    <div>
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings" style={{ height: '35px', fontSize: '12px', marginRight: '20px' }}>Cookie Settings</button>

                        <a className="footerLink" target="_blank" href="https://www.straumann.com/en/dental-professionals/legal-notice.html">
                            Legal notice
                        </a>
                        <span className="footerseparater">|</span>
                        <a className="footerLink" target="_blank" href="https://www.straumann.com/group/en/discover/privacy/com/en.html">
                            Privacy notice
                        </a>
                        <span className="footerseparater">|</span>
                        <a className="footerLink" target="_blank" href="https://www.straumann.com/en/dental-professionals/imprint.html">
                            Imprint
                        </a>
                    </div>
                </Stack>
            </Stack>
        );
    }

    public componentDidMount() {
        var dataProvider = new DataProvider();
        dataProvider.getAppValues()
            .then((appValues) => {
                console.log(appValues);
                var showBanner = false;
                var bannerText = "";
                if (!appValues.hasError) {
                    showBanner = appValues.data.showBanner;
                    bannerText = appValues.data.bannerText;
                    this.props.setAppSettings(appValues.data);
                }
                else {
                    console.log("Error in getting banner data.");
                    console.log(appValues.errorDetail);
                    showBanner = false;
                    bannerText = "Error in getting banner data.";
                }

                this.setState({
                    ShowBanner: showBanner,
                    BannerData: bannerText
                }, () => {
                });

            })
            .catch((error) => {
                console.log(error);
            });
    }
}
