import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { Label } from 'office-ui-fabric-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CommentMode } from '../../Utility/FreelancerEnums';
import { Utility } from '../../Utility/Utility';
import './Common.scss';
export interface IModalCommentProps {
    header: string;
    message: string;
    buttonText: string;
    confirmed: any;
    hideConfirmedDialog: any;
}


export const Confirmation: React.FunctionComponent<IModalCommentProps> = ({ ...props }) => {
    useEffect(() => {

    });

    return (
        <>
            <Dialog
                hidden={false}
                onDismiss={props.hideConfirmedDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: props.header,
                    subText: props.message,
                }}
                modalProps={{
                    isBlocking: true,
                    // styles: { main: { maxWidth: 800 } },
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => props.confirmed()} text={props.buttonText} />
                    <DefaultButton onClick={() => props.hideConfirmedDialog()} text="Cancel" />
                </DialogFooter>
            </Dialog>
        </>
    );
};
