import * as React from 'react';
import { UserProfile } from './UserProfile/UserProfile'
import { UserEvents } from './Events/UserEvents'
import { css, Stack, PrimaryButton, Text, getTheme, Link, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, IDetailsRowProps, DetailsRow, Spinner, IconButton, Dialog, DialogFooter, DefaultButton, ConstrainMode, Icon } from 'office-ui-fabric-react';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { IDataProvider } from '../../../dataprovider/IDataProvider';
import { AccountType, IUserAccount } from '../../LoginComponent/LoginComponent';
import { ProfileManagement } from './Admin/ProfileManagement';
import { ProfileView } from '../../Utility/FreelancerEnums';
import { FreelancerContext } from '../Common/FreelancerContext';
import { AppConstants } from '../../Utility/Constants';
import { Utility } from '../../Utility/Utility';
export interface IDefaultViewProps {
    dataProvider: IDataProvider;
    currentUser?: IUserAccount;
}

export interface IDefaultViewState {
    LoginUserDetail: any;
    MasterData: any;
    isUserAdmin: boolean;
    IsProfilePresent: boolean;
    TabOrder: number;
}

export class UserDashBoard extends React.Component<IDefaultViewProps, IDefaultViewState> {
    constructor(MyProps: IDefaultViewProps) {
        super(MyProps);
        var tabOrder = -1;
        this.state = {
            LoginUserDetail: null,
            MasterData: null,
            isUserAdmin: false,
            IsProfilePresent: false,
            TabOrder: tabOrder,
        };
        this.GetUserData = this.GetUserData.bind(this);
    }

    /// get context type
    static contextType = FreelancerContext;

    public render() {
        return (
            <Stack horizontal className="fullWidth" >
                {this.state.TabOrder >= 0 &&
                    <Tabs className="userProfileTab" defaultIndex={this.state.TabOrder}  >
                        <TabList>
                            {
                                (this.state.LoginUserDetail.userDetail?.status == AppConstants.ProfileStatus.Active
                                    || this.state.isUserAdmin == true)
                                &&
                                <Tab>Events</Tab>
                            }
                            {!this.state.isUserAdmin &&
                                <Tab>My Profile</Tab>
                            }
                            {this.state.isUserAdmin &&
                                <Tab>Manage Profiles</Tab>
                            }

                        </TabList>
                        {
                            (this.state.LoginUserDetail.userDetail?.status == AppConstants.ProfileStatus.Active
                                || this.state.isUserAdmin == true)
                            &&
                            <TabPanel>
                                <UserEvents
                                    loginUserDetail={this.state.LoginUserDetail.userDetail}
                                    dataProvider={this.props.dataProvider}
                                    masterData={this.state.MasterData}
                                    isUserAdmin={this.state.isUserAdmin}
                                />

                            </TabPanel>
                        }
                        {!this.state.isUserAdmin &&
                            <TabPanel>
                                {this.state.LoginUserDetail.userDetail &&
                                    <UserProfile
                                        dataProvider={this.props.dataProvider}
                                        ReloadLoginProfile={this.GetUserData}
                                        ViewType={ProfileView.User}
                                        UserDetail={this.state.LoginUserDetail.userDetail}
                                        MasterData={this.state.MasterData}
                                        IsCustomAccount={this.props.currentUser.accountType.toLowerCase() == AccountType.Custom.toLowerCase()}
                                    />
                                }
                            </TabPanel>
                        }
                        {this.state.isUserAdmin &&
                            <TabPanel>
                                {this.state.MasterData && <ProfileManagement
                                    dataProvider={this.props.dataProvider}
                                    currentUser={this.props.currentUser}
                                    MasterData={this.state.MasterData}
                                />
                                }
                            </TabPanel>
                        }

                    </Tabs>
                }
            </Stack>

        );
    }

    public GetUserData() {
        return new Promise((resolve, reject) => {
            if (this.props.currentUser?.email && this.props.currentUser?.id) {
                var loginUserDetail = this.state.LoginUserDetail;
                if (loginUserDetail) {
                    loginUserDetail.userDetail = null;
                    this.setState({ LoginUserDetail: loginUserDetail });
                }
                var userProfileQuery = {
                    Value: this.props.currentUser?.email,
                    Type: AppConstants.ProfileQueryType.UserEmail
                };
                if (Utility.IsLoginUserActive(this.context.ExpireSession)) {
                    this.context.ShowFreelanceLoader(true, "");
                    Promise.all([
                        this.props.dataProvider.getUserProfile(userProfileQuery),
                        this.props.dataProvider.getMasterData(),
                        this.props.dataProvider.getUserType(this.props.currentUser?.email)
                    ])
                        .then((values) => {
                            var isUserProfilePresent = false;
                            var tabOrder = 0;
                            var userDetail = values[0];
                            if (!userDetail.userDetail) {
                                tabOrder = 1;
                                userDetail.userDetail = {
                                    firstName: this.props.currentUser?.firstName,
                                    lastName: this.props.currentUser?.lastName,
                                    mobileNo: "",
                                    userAccountId: this.props.currentUser?.id,
                                    title: this.props.currentUser?.email,
                                    accountType: this.props.currentUser?.accountType,
                                }
                            }
                            else {
                                userDetail.userDetail.userAccountId = this.props.currentUser?.id;
                                userDetail.userDetail.accountType = this.props.currentUser?.accountType;
                                isUserProfilePresent = true;
                            }
                            var isUserAdmin = values[2].userStatus == AppConstants.LoginUser.Admin ? true : false;
                            if (isUserAdmin) {
                                tabOrder = 0;
                            }
                            if (isUserAdmin) {
                                var tab = Utility.getTabDueToParameter();
                                if (tab > -1) {
                                    tabOrder = tab;
                                }
                            }
                            if (!isUserAdmin && userDetail.userDetail?.status != AppConstants.ProfileStatus.Active) {
                                tabOrder = 1;
                            }
                            this.setState({
                                TabOrder: tabOrder,
                                IsProfilePresent: isUserProfilePresent,
                                LoginUserDetail: userDetail,
                                MasterData: values[1],
                                isUserAdmin: isUserAdmin
                            }, () => {
                                this.context.ShowFreelanceLoader(false, "");
                            });
                            resolve("success");

                        }).catch((error) => {
                            resolve("fail");
                            console.log(error);
                            this.context.ShowFreelanceLoader(false, "");
                            this.context.ShowFreelancerModal("Error", "Error in getting user profile and master data.");
                        });
                }
            }
        });
    }
    public async componentDidMount() {
        var v = await this.GetUserData();
    }
}